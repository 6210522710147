import { useState } from 'react';

export const useMultiSelect = <T extends unknown & { id: string }>(array: T[]) => {
  const [firstSelectionId, setFirstSelectionId] = useState<string>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const idsArray = array?.map((w) => w.id) ?? [];

  const selectedLength = selectedIds.length;
  const allSelected = array.length === selectedLength;

  const handleSelectDeselectAll = () => {
    if (allSelected) {
      setSelectedIds([]);
      return;
    }
    setSelectedIds(idsArray);
  };

  const handleSelect = (e: React.MouseEvent<Element>, itemId: string) => {
    const isAlreadySelected = selectedIds.includes(itemId);
    if (e.shiftKey && firstSelectionId) {
      // get indexes of multiselection
      const currSelectionIdx = array?.findIndex((w) => w.id === itemId) ?? -1;
      const firstSelectionIdx = array?.findIndex((w) => w.id === firstSelectionId) ?? -1;
      //order them by lowest to highest for slice
      const [firstIdx = -1, lastIdx = -1] = [currSelectionIdx, firstSelectionIdx].sort(
        (a, b) => a - b
      );

      const selectedWorkers = array?.slice(firstIdx + 1, lastIdx + 1);
      const selectedIds = selectedWorkers?.map((w) => w.id) ?? [];

      //reset point to most current interaction
      setFirstSelectionId(itemId);

      if (isAlreadySelected) {
        const filteredWorkers = selectedIds.filter((w) => !selectedIds?.includes(w));
        setSelectedIds(filteredWorkers);
        return;
      }
      setSelectedIds((curr) => [...curr, ...selectedIds]);
      return;
    }
    if (isAlreadySelected) {
      const filteredWorkers = selectedIds.filter((w) => w !== itemId);
      setSelectedIds(filteredWorkers);
      return;
    }
    setSelectedIds((curr) => [...curr, itemId]);
    setFirstSelectionId(itemId);
  };

  return { allSelected, selectedLength, selectedIds, handleSelectDeselectAll, handleSelect };
};
