import { CustomerProfile } from './customer-profile.types';
import { Customer } from './customer.types';
import { DateString } from './types';

export enum BatchFileType {
  Worker = 'worker',
  Payout = 'payout',
  Taxes1099 = 'taxes1099',
}

// Double checked with Joi Validation
export interface PayoutBatchData {
  workerIdType: 'checkrPayId' | 'metadata';
  description: string;
  workerCheckrPayId?: string;
  workerMetadata?: string;
  amountCents?: number;
  payoutMetadata?: string;
  requestId?: string;
}

export interface WorkerBatchData {
  metadata?: string;
  piiType: 'backgroundCheckId' | 'manual';
  backgroundCheckId?: string;
  ssn?: string;
  dateOfBirth?: Date | string;
  firstName?: string;
  lastName?: string;

  // Profile fields
  phoneNumber: string;
  email?: string;
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
}

export interface Taxes1099BatchData {
  workerIdType: 'checkrPayId' | 'metadata';
  workerCheckrPayId?: string;
  workerMetadata?: string;
  nonPlatformEarningsAmount?: number;
}

export enum BatchFileStatus {
  Pending = 'pending',
  Started = 'started',
  Success = 'success',
  ManualReview = 'manual_review',
  Error = 'error',
  Cancelled = 'cancelled',
  SystemError = 'system_error',
}

export interface BatchFile {
  _id: string;
  createdAt: DateString;
  customer?: Customer['_id'];
  customerProfile?: Pick<
    CustomerProfile,
    | '_id'
    | 'customer'
    | 'email'
    | 'stytchOrgMemberId'
    | 'stytchOrgId'
    | 'permissions'
    | 'createdAt'
    | 'updatedAt'
    | '__v'
    | 'disabled'
    | 'lastLogin'
  >;
  failureCount?: number;
  inputFileData?: string;
  inputFileName: string;
  returnFileData?: string;
  returnFileName?: string;
  runAt?: DateString;
  status: BatchFileStatus;
  successCount?: number;
  type: BatchFileType;
}

export interface BatchFileUploadType {
  type: BatchFileType;
}

export type BatchUploadErrorResponse = {
  details: Array<
    | 'invalid_file_type'
    | 'invalid_file'
    | 'missing_batch_file'
    | 'batch_file_already_processed'
    | 'invalid_schedule'
  >;
};
