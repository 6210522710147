import { Button, Spinner } from '@checkrx/pay-component-library';

import { toast } from '@/app/wrappers/Toaster/Toaster';
import { BulkFileResp, useBulkFileTaxes } from '@/services/useWorkerTaxes';
import { ContentWrapper, DescriptionText } from '@/shared/components/ConfirmationModals.styled';
import { CUSTOMER_SUPPORT_URL } from '@/shared/constants';
import { colors } from '@/shared/styles';
import { generateAndDownloadCsv } from '@/shared/util';
import { pluralize } from '@/shared/utils/pluralize';
import { useState } from 'react';
import styled from 'styled-components';
import { TaxYears, WorkerWithEarnings } from './taxes.types';

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 24px;
  margin-top: 16px;
`;

const SupportText = styled.a`
  color: ${colors.textGrey};
  text-decoration: underline;
  &:hover {
    filter: brightness(90%);
    transition: 0.2s;
    text-decoration: underline;
  }
`;

const ErrorText = styled.span`
  font-weight: 600;
  color: ${colors.accentRed};
`;

const SuccessText = styled.span`
  font-weight: 600;
  color: ${colors.accentGreen};
`;

type MultiFileModalProps = {
  workerIds: WorkerWithEarnings['id'][];
  taxYear: TaxYears;
  onClose: () => void;
};

const MultiFileModal = ({ workerIds, taxYear, onClose }: MultiFileModalProps) => {
  const {
    mutateAsync: bulkFileTaxes,
    isLoading: bulkFileLoading,
    isSuccess: bulkFileCompleted,
  } = useBulkFileTaxes();
  const [errors, setErrors] = useState<BulkFileResp[]>([]);
  const [success, setSuccess] = useState<BulkFileResp[]>([]);
  const handleBulkFile = async () => {
    try {
      const resp = await bulkFileTaxes({ workerProfileIds: workerIds, year: taxYear });
      const respErrors: BulkFileResp[] = [];
      const respSuccess: BulkFileResp[] = [];
      resp.forEach((r) => {
        if (r.status === 'ERROR') {
          respErrors.push(r);
          return;
        }
        respSuccess.push(r);
        return;
      });
      if (respErrors.length === 0) {
        toast({
          message: `Filed taxes successfully for ${workerIds.length} earners`,
          type: 'success',
          duration: 5000,
        });
        setSuccess(respSuccess);
      } else {
        setErrors(respErrors);
        setSuccess(respSuccess);
      }
    } catch (err) {
      toast({
        message: `Failed to file, please contact support`,
        type: 'error',
        duration: 5000,
      });
    }
  };

  const getDescriptionText = () => {
    if (bulkFileCompleted && errors.length > 0 && success.length > 0) {
      return (
        <>
          Successfully filed for{' '}
          <SuccessText>
            {success.length} {pluralize('earner', success.length)}
          </SuccessText>
          , but <ErrorText>{errors.length} failed</ErrorText>.
        </>
      );
    }
    if (bulkFileCompleted && errors.length > 0 && success.length === 0) {
      return (
        <>
          Failed to file for{' '}
          <ErrorText>
            {errors.length} {pluralize('earner', errors.length)}
          </ErrorText>
          .
        </>
      );
    }
    if (bulkFileCompleted && errors.length === 0) {
      return (
        <>
          You have successfully filed for{' '}
          <SuccessText>
            {success.length} {pluralize('earner', success.length)}
          </SuccessText>
          .
        </>
      );
    }
    return (
      <>
        You are proceeding to file for{' '}
        <b>
          {workerIds.length} {pluralize('earner', workerIds.length)}
        </b>
        . This action cannot be undone!
      </>
    );
  };

  const handleGenerateCsv = () => {
    generateAndDownloadCsv([...success, ...errors], (respItem) => ({
      id: respItem.workerProfileId,
      status: respItem.status,
      error: respItem.errMessage ?? '',
    }));
  };

  return (
    <ContentWrapper>
      <DescriptionText>{getDescriptionText()}</DescriptionText>
      {errors.length > 0 && (
        <SupportText href={CUSTOMER_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
          Please try again or contact support
        </SupportText>
      )}
      <ButtonRow>
        {bulkFileCompleted && (
          <Button
            text="Get status CSV"
            sizeVariant="big"
            width="155px"
            onClick={handleGenerateCsv}
          />
        )}
        <Button
          text={bulkFileCompleted ? 'Close' : 'File'}
          sizeVariant="big"
          colorVariant="brand"
          width="155px"
          onClick={bulkFileCompleted ? onClose : handleBulkFile}
        />
        {bulkFileLoading && <Spinner size="30px" />}
      </ButtonRow>
    </ContentWrapper>
  );
};

export default MultiFileModal;
