export enum BankingAccountStatus {
  Open = 'Open',
  Closed = 'Closed',
  Frozen = 'Frozen',
}

export enum DepositProduct {
  Checking = 'checking',
}

type Limits = {
  dailyDebit: number;
  dailyCredit: number;
  monthlyDebit: number;
  monthlyCredit: number;
  dailyDebitSoft: number;
  monthlyDebitSoft: number;
};

type TotalsDailyMonthly = {
  debits: number;
  credits: number;
};

export interface BankingAccount {
  accountId: string;
  accountStatus: BankingAccountStatus;
  userId: string;
  depositProduct: DepositProduct;
  routingNumber: string;
  accountNumber: string;
  balance: number;
  available: number;
  name: string;
  limits: {
    id: string;
    ach: {
      limits: Limits;
      totalsDaily: TotalsDailyMonthly;
      totalsMonthly: TotalsDailyMonthly;
    };
    bookTransfer: {
      limits: Limits;
      totalsDaily: TotalsDailyMonthly;
      totalsMonthly: TotalsDailyMonthly;
    };
    card: {
      limits: {
        dailyWithdrawal: number;
        dailyDeposit: number;
        dailyPurchase: number;
        dailyCardTransaction: number;
      };
      totalsDaily: {
        withdrawals: number;
        deposits: number;
        purchases: number;
        cardTransactions: number;
      };
    };
    checkDeposit: {
      limits: {
        daily: number;
        monthly: number;
        dailySoft: number;
        monthlySoft: number;
      };
      totalsDaily: number;
      totalsMonthly: number;
    };
    checkPayment: {
      limits: {
        dailySentSoft: number;
        monthlySentSoft: number;
      };
      totalsDaily: {
        sent: number;
      };
      totalsMonthly: {
        sent: number;
      };
    };
    wire: {
      limits: {
        dailyTransfer: number;
        monthlyTransfer: number;
        dailyTransferSoft: number;
        monthlyTransferSoft: number;
      };
      totalsDaily: {
        transfers: number;
      };
      totalsMonthly: {
        transfers: number;
      };
    };
  };
}
