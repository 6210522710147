import { toast } from '@/app/wrappers/Toaster/Toaster';
import {
  useDownloadStatementCsv,
  useDownloadStatementPdf,
  useStatements,
} from '@/services/useStatements';
import { ReactComponent as DownloadIcon } from '@/shared/assets/download-icon.svg';
import { CardTitle, ContentCard } from '@/shared/components/Card';
import { DownloadButton } from '@/shared/components/DownloadButton.styled';
import { TablePagination } from '@/shared/components/TablePagination';
import { Statement } from '@/shared/types';
import { Spinner, Table } from '@checkrx/pay-component-library';
import { captureException } from '@sentry/browser';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days2';
import { useState } from 'react';
import styled from 'styled-components';

dayjs.extend(dayjsBusinessDays);

const StatementsCard = styled(ContentCard)`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  min-height: fit-content;
  gap: 30px;
`;

const DownloadSection = styled.div`
  display: flex;
  gap: 24px;
`;

// Default to 1-year of statements instead of the default number
const STATEMENTS_PER_PAGE = 12;

export default function StatementsTable() {
  const [pageNumber, setPageNumber] = useState(0);

  const { data: fetchedStatements, isLoading, isError } = useStatements();

  const startIdx = pageNumber * STATEMENTS_PER_PAGE;
  const endIdx = (pageNumber + 1) * STATEMENTS_PER_PAGE;
  const statementsForTable = (fetchedStatements || []).slice(startIdx, endIdx).map((statement) =>
    // Map fetched transactions to displayable data
    ({
      period: dayjs(statement.period).format('MMMM YYYY'),
      id: statement.statementId,
      download: <DownloadTableCell statement={statement} />,
    })
  );
  const lastPage = Math.floor((statementsForTable || []).length / STATEMENTS_PER_PAGE);

  return (
    <StatementsCard>
      <CardTitle>Statements</CardTitle>
      <Table
        data={statementsForTable}
        error={isError}
        loading={isLoading}
        empty={(statementsForTable || []).length === 0}
        columns={[
          { title: 'ID', field: 'id', flex: 2 },
          { title: 'Date', field: 'period', flex: 3 },
          {
            title: 'Download Statement',
            field: 'download',
            flex: 2,
            isText: false,
          },
        ]}
        width="100%"
        height="fit-content"
      />
      {lastPage > 0 && (
        <TablePagination
          curPageNumber={pageNumber}
          lastPageNumber={lastPage}
          setCurPageNumber={setPageNumber}
        />
      )}
    </StatementsCard>
  );
}

function DownloadTableCell({ statement }: { statement: Statement }) {
  const { mutateAsync: downloadStatementPdf, isLoading: isDownloadingStatementPdf } =
    useDownloadStatementPdf();

  const { mutateAsync: downloadStatementCsv, isLoading: isDownloadingStatementCsv } =
    useDownloadStatementCsv();

  return (
    <DownloadSection>
      <DownloadButton
        type="button"
        disabled={isDownloadingStatementPdf}
        onClick={async () => {
          try {
            await downloadStatementPdf(statement.statementId);
          } catch (err) {
            captureException(err, {
              extra: {
                location: 'Download statement PDF',
              },
            });
            toast({ type: 'error', message: 'Failed to download statement PDF', duration: 5000 });
          }
        }}
      >
        {isDownloadingStatementPdf ? <Spinner size="15px" /> : <DownloadIcon />}
        <span>Download PDF</span>
      </DownloadButton>

      <DownloadButton
        type="button"
        disabled={isDownloadingStatementCsv}
        onClick={async () => {
          try {
            await downloadStatementCsv({
              statementId: statement.statementId,
              month: statement.period,
            });
          } catch (err) {
            captureException(err, {
              extra: {
                location: 'Download statement CSV',
              },
            });
            toast({ type: 'error', message: 'Failed to download statement CSV', duration: 5000 });
          }
        }}
      >
        {isDownloadingStatementCsv ? <Spinner size="15px" /> : <DownloadIcon />}
        <span>Download CSV</span>
      </DownloadButton>
    </DownloadSection>
  );
}
