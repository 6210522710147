import { Button } from '@checkrx/pay-component-library';
import { FC } from 'react';
import styled from 'styled-components';
import { PreviousWorkModal, TaxYears, WorkerWithEarnings } from './taxes.types';

const ActionsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const DisabledHelperText = styled.p`
  font-size: 12px;
`;

const DisabledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

type Props = {
  worker: WorkerWithEarnings;
  taxYear: TaxYears;
  onViewPdf: (worker: WorkerWithEarnings) => void;
  onOpenPreviousWorkModal: (type: PreviousWorkModal, worker: WorkerWithEarnings) => void;
  onOpenConfirmFileModal: (worker: WorkerWithEarnings) => void;
};

export const WorkerTaxAction: FC<Props> = ({
  worker,
  taxYear,
  onOpenPreviousWorkModal,
  onOpenConfirmFileModal,
  onViewPdf,
}) => {
  const viewPdfButton = worker?.['1099nec']?.[taxYear]?.payeeUrl ? (
    <Button text="View PDF" onClick={() => onViewPdf(worker)} />
  ) : null;
  if (!worker?.['1099nec']?.[taxYear]?.status) {
    return worker.status !== 'active' ? (
      <DisabledButtonContainer>
        <Button
          text="Generate 1099-NEC"
          onClick={() => onOpenPreviousWorkModal('create', worker)}
          disabled
        />
        <DisabledHelperText>Earner must be active</DisabledHelperText>
      </DisabledButtonContainer>
    ) : (
      <Button text="Generate 1099-NEC" onClick={() => onOpenPreviousWorkModal('create', worker)} />
    );
  }
  if (worker?.['1099nec']?.[taxYear]?.status === 'CREATED') {
    return (
      <ActionsContainer>
        <Button text="File 1099-NEC" onClick={() => onOpenConfirmFileModal(worker)} />
        {viewPdfButton}
      </ActionsContainer>
    );
  }
  return (
    <ActionsContainer>
      <Button
        text="Request correction"
        onClick={() => onOpenPreviousWorkModal('correction', worker)}
      />
      {viewPdfButton}
    </ActionsContainer>
  );
};
