import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as BatchUploadIcon } from '@/shared/assets/batch-upload.svg';
import { CardTitle, ContentCard } from '@/shared/components/Card';

import BatchFileUpload from '@/shared/components/BatchFileUpload';
import { BatchUploadIconButton, BatchUploadIconText } from '@/shared/components/BatchUpload.styled';
import { BatchFileType, Worker } from '@/shared/types';
import { isAmount, isCsvSafeString, isWorkerId } from '@/shared/validation';
import {
  Button,
  DropdownInput,
  Modal,
  Spinner,
  TextArea,
  TextInput,
} from '@checkrx/pay-component-library';

import { textareaStripper } from '@/services/textarea-stripper';
import { useBankingAccountBalance } from '@/services/useBankingAccount';
import { useWorkers } from '@/services/useWorkers';
import { BatchFileHelpLink } from '@/shared/components/BatchFileHelpLink';
import { ValueText } from '@/shared/components/ConfirmationModals.styled';
import {
  ControlsCol,
  ControlsRow,
  CreateOneCol,
  ErrorText,
  LabelRow,
  LabelText,
  SubmitButtonBox,
  SuccessText,
} from '@/shared/components/CreationComponents';
import { colors } from '@/shared/styles';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import PayoutConfirmationModal from './PayoutConfirmationModal';

const SendPayoutsCardWrapper = styled(ContentCard)`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  min-height: fit-content;

  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`;

const BalancesLabelRow = styled(LabelRow)`
  border: 1px ${colors.dividerGrey} solid;
  border-radius: 10px;
  padding: 15px;
`;

const LoadingWorkers = styled.div`
  width: 368px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: ${colors.dividerGrey};
  border-radius: 10px;
  padding-left: 40px;
  cursor: not-allowed;
  font-size: 14px;
  color: ${colors.textGrey};
`;

export default function SendPayoutsCard() {
  const [workerId, setWorkerId] = useState<string | null>(null);
  const [paymentAmount, setPaymentAmount] = useState<number | null>(null);
  const [metadata, setMetadata] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);

  // Error States
  const [workerIdErr, setWorkerIdErr] = useState<string | null>(null);
  const [paymentAmountErr, setPaymentAmountErr] = useState<string | null>(null);
  const [metadataErr, setMetadataErr] = useState<string | null>(null);
  const [descriptionErr, setDescriptionErr] = useState<string | null>(null);

  // Modal State for Batch Files
  const [batchModalOpen, setBatchModalOpen] = useState(false);
  const [confirmPayoutModalOpen, setConfirmPayoutModalOpen] = useState(false);

  // worker dropdown
  const [workerSearch, setWorkerSearch] = useState<string>('');
  const { data: workers, isLoading: loadingWorkers } = useWorkers();
  const { data: balance } = useBankingAccountBalance();
  const searchedWorkers = (workers ?? []).filter((w) => {
    const joinedInfo = `${w.id} ${w.profile?.email} ${w.profile?.legalName}`;
    return joinedInfo.toLowerCase().includes(workerSearch.toLowerCase());
  });

  const generateWorkerDropdownLabel = (worker: Worker) => {
    if (worker.profile?.legalName && worker.profile.email) {
      return `${worker.profile?.legalName} - ${worker.profile?.email}`;
    }
    if (worker.profile?.legalName && !worker.profile.email) {
      return worker.profile.legalName;
    }
    if (!worker.profile?.legalName && worker.profile?.email) {
      return worker.profile.email;
    }
    return worker.id;
  };

  const workerDropdownOptions =
    searchedWorkers?.map((w) => ({
      label: generateWorkerDropdownLabel(w),
      value: w.id,
    })) ?? [];

  // On successful submission, clear the current state
  function clear() {
    setPaymentAmount(null);
    setMetadata(null);
    setDescription(null);
  }

  const validateInputs = () => {
    let canSubmit = true;

    // Check Amount
    if (!paymentAmount) {
      canSubmit = false;
      setPaymentAmountErr('Payment amount is blank. Please enter valid payment amount');
    } else if (!isAmount(paymentAmount)) {
      canSubmit = false;
      setPaymentAmountErr('Payment amount is invalid. Please enter a valid payment amount');
    } else {
      setPaymentAmountErr(null);
    }

    // Check WorkerId
    if ((workerId || '').length < 1) {
      canSubmit = false;
      setWorkerIdErr('Worker Id is blank. Please enter valid Worker Id.');
    } else if (!isWorkerId(workerId)) {
      canSubmit = false;
      setWorkerIdErr('Worker Id is invalid. Please enter a valid workerId');
    } else {
      setWorkerIdErr(null);
    }

    // Optional Description
    if (description && description.length > 0) {
      if (description.length > 1000) {
        canSubmit = false;
        setDescriptionErr('Description cannot be more than 1000 characters');
      } else if (!isCsvSafeString(description)) {
        canSubmit = false;
        setDescriptionErr('Description cannot begin with special characters +, -, =, or @');
      } else {
        setDescriptionErr(null);
      }
    } else {
      setDescriptionErr(null);
    }
    // Optional Metadata
    if (metadata && metadata.length > 0) {
      if (metadata.length > 256) {
        canSubmit = false;
        setMetadataErr('Metadata cannot be more than 256 characters');
      } else if (!isCsvSafeString(metadata)) {
        canSubmit = false;
        setMetadataErr('Metadata cannot begin with special characters +, -, =, or @');
      } else {
        setMetadataErr(null);
      }
    } else {
      setMetadataErr(null);
    }
    return canSubmit;
  };

  const onSubmitOnePayment = async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const canProceed = validateInputs();
    if (canProceed) {
      if (workerId && paymentAmount) {
        setConfirmPayoutModalOpen(true);
      }
    }
  };

  // Listen for "Enter" key throughout the form
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      onSubmitOnePayment(event);
    }
  };

  const strippedPayoutDescription = textareaStripper(description ?? '');
  const strippedPayoutMetadata = textareaStripper(metadata ?? '');

  return (
    <SendPayoutsCardWrapper>
      <Modal
        isOpen={confirmPayoutModalOpen}
        close={() => {
          setConfirmPayoutModalOpen(false);
        }}
        headerText="Payout Confirmation"
        subHeaderText="Double check this information before sending your payout"
        modalContent={
          // NOTE(Carter): Null assertion is fine here because we validate above
          <PayoutConfirmationModal
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            workerId={workerId!}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            paymentAmount={paymentAmount!}
            clearParentFn={clear}
            metadata={metadata ? strippedPayoutMetadata : undefined}
            description={description ? strippedPayoutDescription : undefined}
          />
        }
      />
      <Modal
        isOpen={batchModalOpen}
        close={() => {
          setBatchModalOpen(false);
        }}
        headerText="Upload Payout Batch File"
        subHeaderText="Pay multiple workers with a single click"
        modalContent={<BatchFileUpload type={BatchFileType.Payout} />}
      />
      <CreateOneCol onKeyDown={onKeyDown}>
        <CardTitle>Send Payouts</CardTitle>
        <BalancesLabelRow>
          <LabelText>Current Account Balance: </LabelText>
          <ValueText>
            <SuccessText>{formatCentsAsDollars(balance ?? 0)}</SuccessText>
          </ValueText>
        </BalancesLabelRow>
        <ControlsRow>
          <ControlsCol>
            <LabelRow invalid={!!workerIdErr}>
              <LabelText>Worker</LabelText>
              <LabelText>*</LabelText>
            </LabelRow>
            {loadingWorkers ? (
              <LoadingWorkers>
                <div>Loading workers</div> <Spinner size="24px" />
              </LoadingWorkers>
            ) : (
              <DropdownInput
                options={workerDropdownOptions}
                value={workerId ?? ''}
                onSelect={(v) => setWorkerId(v)}
                onChange={(v) => setWorkerSearch(v)}
                width="368px"
                height="60px"
                invalid={!!workerIdErr}
                placeholder="Search by worker name, email, or id"
                disableInternalSearch
                closeOnChange
              />
            )}
            {workerIdErr && <ErrorText maxWidth="280px">{workerIdErr}</ErrorText>}
          </ControlsCol>
          <ControlsCol>
            <LabelRow invalid={!!paymentAmountErr}>
              <LabelText>Payment Amount</LabelText>
              <LabelText>*</LabelText>
            </LabelRow>
            <TextInput
              width="188px"
              placeholder="Payment Amount"
              height="60px"
              invalid={!!paymentAmountErr}
              value={
                paymentAmount
                  ? Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format((paymentAmount || 0) / 100)
                  : undefined
              }
              onChange={(e) => {
                // Parse from Intl number format to integer value
                if (e.target.value.includes('$')) {
                  setPaymentAmount(
                    parseInt(e.target.value.slice(1).replaceAll(',', '').split('.').join(''))
                  );
                } else {
                  setPaymentAmount(parseInt(e.target.value.replaceAll(',', '')));
                }
              }}
            />
            {paymentAmountErr && <ErrorText maxWidth="280px">{paymentAmountErr}</ErrorText>}
          </ControlsCol>
          <SubmitButtonBox>
            <Button
              text="Submit"
              sizeVariant="big"
              colorVariant="brand"
              width="155px"
              onClick={onSubmitOnePayment}
              disabled={!workerId || (paymentAmount ?? 0) < 1}
            />
          </SubmitButtonBox>
        </ControlsRow>
        <ControlsRow>
          <ControlsCol>
            <LabelRow invalid={!!descriptionErr}>
              <LabelText>Payout Description</LabelText>
            </LabelRow>
            <TextArea
              width="367px"
              height="100px"
              maxLength={256}
              invalid={!!descriptionErr}
              value={description ?? undefined}
              placeholder="Optional Payout Description"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            {descriptionErr && <ErrorText maxWidth="367px">{descriptionErr}</ErrorText>}
          </ControlsCol>
          <ControlsCol>
            <LabelRow invalid={!!metadataErr}>
              <LabelText>Metadata</LabelText>
            </LabelRow>
            <TextArea
              width="367px"
              height="100px"
              maxLength={256}
              placeholder="Optional Payout Metadata"
              invalid={!!metadataErr}
              value={metadata ?? undefined}
              onChange={(e) => {
                setMetadata(e.target.value);
              }}
            />
            {metadataErr && <ErrorText maxWidth="367px">{metadataErr}</ErrorText>}
          </ControlsCol>
        </ControlsRow>
      </CreateOneCol>
      <BatchFileHelpLink>
        <BatchUploadIconButton
          type="button"
          onClick={() => {
            setBatchModalOpen(true);
          }}
        >
          <BatchUploadIcon />
          <BatchUploadIconText>Batch Payment</BatchUploadIconText>
        </BatchUploadIconButton>
      </BatchFileHelpLink>
    </SendPayoutsCardWrapper>
  );
}
