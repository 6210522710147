import dayjs from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';

import { CardTitle, ContentCard } from '@/shared/components/Card';
import { Button, Modal, Table, TextInput } from '@checkrx/pay-component-library';

import { useUsers } from '@/services/useUsers';

import { useCustomerProfilePermissions } from '@/services/useCustomerProfile';
import { ReactComponent as EditIcon } from '@/shared/assets/edit-pencil.svg';
import {
  ControlsRow,
  DEFAULT_RESULTS_PER_TABLE_PAGE,
  TableStatusText,
} from '@/shared/components/TableComponents';
import { TablePagination } from '@/shared/components/TablePagination';
import { BetweenWrapper } from '@/shared/components/Wrappers.styled';
import { colors } from '@/shared/styles';
import { CustomerProfile, Permissions, TabPermissions } from '@/shared/types';
import { generateAndDownloadCsv } from '@/shared/util';
import UserEditModal from './UserEditModal';

const UsersCard = styled(ContentCard)`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  min-height: fit-content;
  gap: 30px;
`;
const EditButton = styled.button<{ disabled?: boolean }>`
  width: fit-content;
  height: fit-content;
  background: none;
  color: ${colors.textGrey};
  ${(p) =>
    p.disabled
      ? // If disabled, make it lighter
        `
      filter: brightness(130%);
    `
      : // Otherwise make darker on hover
        `
      &:hover {
        color: ${colors.labelGrey};
        cursor: pointer;
      }
    `}
`;
const userToStatus = (user: CustomerProfile) => {
  if (user.disabled) {
    return <TableStatusText color={colors.accentRed}>Disabled</TableStatusText>;
  }

  if (user.lastLogin) {
    return <TableStatusText color={colors.accentGreen}>Active</TableStatusText>;
  }

  return <TableStatusText color={colors.accentOrange}>Pending</TableStatusText>;
};

const readablePermission = (p: TabPermissions | undefined) => {
  if (p === TabPermissions.write) {
    return 'Read and Write';
  } else if (p === TabPermissions.read) {
    return 'Read-only';
  } else {
    return 'None';
  }
};

function permissionsToDisplay(privileges: Permissions) {
  const {
    usersTab,
    workersTab,
    payoutsTab,
    financeTab,
    sensitiveAccountData,
    taxTab,
    executeRepayments,
  } = privileges;

  const inverted = {
    write: [
      payoutsTab === TabPermissions.write ? 'Payouts' : undefined,
      workersTab === TabPermissions.write ? 'Workers' : undefined,
      usersTab === TabPermissions.write ? 'Users' : undefined,
      taxTab === TabPermissions.write ? 'Taxes' : undefined,
      financeTab === TabPermissions.write ? 'Finances' : undefined,
      sensitiveAccountData === TabPermissions.write ? 'Sensitive Data' : undefined,
      executeRepayments === TabPermissions.write ? 'Execute repayments' : undefined,
    ]
      .filter((v) => v)
      .join(', '),
    read: [
      payoutsTab === TabPermissions.read ? 'Payouts' : undefined,
      workersTab === TabPermissions.read ? 'Workers' : undefined,
      usersTab === TabPermissions.read ? 'Users' : undefined,
      taxTab === TabPermissions.read ? 'Taxes' : undefined,
      financeTab === TabPermissions.read ? 'Finances' : undefined,
      sensitiveAccountData === TabPermissions.read ? 'Sensitive Data' : undefined,
      executeRepayments === TabPermissions.read ? 'Execute repayments' : undefined,
    ]
      .filter((v) => v)
      .join(', '),
  };

  return inverted;
}
const USERS_PER_PAGE = DEFAULT_RESULTS_PER_TABLE_PAGE;

export default function UsersTable() {
  const [searchString, setSearchString] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedUser, setSelectedUser] = useState<CustomerProfile | null>(null);

  const { data: fetchedUsers, isError, isLoading } = useUsers(searchString);

  // Should we allow the user to make edits to users from from Table?
  const { data: permissions } = useCustomerProfilePermissions();
  const hasWritePermissions = permissions?.usersTab === TabPermissions.write;

  const startIdx = pageNumber * USERS_PER_PAGE;
  const endIdx = (pageNumber + 1) * USERS_PER_PAGE;
  const usersForTable = (fetchedUsers || []).slice(startIdx, endIdx).map((user) =>
    // Map fetched users to displayable data
    ({
      id: user.email,
      createdAt: dayjs(user.createdAt).format('D MMM YYYY, h:mma'),
      lastLogin: user?.lastLogin ? dayjs(user.lastLogin).format('D MMM YYYY, h:mma') : '',
      status: userToStatus(user),
      readOnlyPrivileges: permissionsToDisplay(user.permissions)?.read,
      readAndWritePrivileges: permissionsToDisplay(user.permissions)?.write,
      action: (
        <EditButton
          disabled={!hasWritePermissions || user.disabled}
          onClick={() => {
            setSelectedUser(user);
          }}
        >
          <EditIcon />
        </EditButton>
      ),
    })
  );
  const lastPage = Math.floor((fetchedUsers || []).length / USERS_PER_PAGE);

  return (
    <UsersCard>
      <Modal
        isOpen={!!selectedUser}
        close={() => {
          setSelectedUser(null);
        }}
        // NOTE(Carter): Null assertion is fine here because we validate above
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        modalContent={<UserEditModal user={selectedUser!} onClose={() => setSelectedUser(null)} />}
      />
      <CardTitle>Active Users</CardTitle>
      <BetweenWrapper>
        <ControlsRow>
          <TextInput
            value={searchString}
            width="400px"
            placeholder="Filter by search term"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchString(e.target.value || '');
              setPageNumber(0);
            }}
          />
        </ControlsRow>
        <Button
          text="Generate CSV"
          sizeVariant="big"
          colorVariant="brand"
          width="165px"
          icon="file-text"
          onClick={() =>
            generateAndDownloadCsv(fetchedUsers || [], (user) => ({
              email: user.email,
              createdAt: dayjs(user.createdAt).format('YYYY-MM-DD HH:mm:ss'),
              lastLogin: user.lastLogin ? dayjs(user.lastLogin).format('YYYY-MM-DD HH:mm:ss') : '',
              isDisabled: user.disabled,
              payoutsTabPermissions: readablePermission(user.permissions?.payoutsTab),
              workersTabPermissions: readablePermission(user.permissions?.workersTab),
              financeTabPermissions: readablePermission(user.permissions?.financeTab),
              sensitiveAccountDataPermissions: readablePermission(
                user.permissions?.sensitiveAccountData
              ),
              usersTabPermissions: readablePermission(user.permissions?.usersTab),
              executeRepayments: readablePermission(user.permissions?.executeRepayments),
            }))
          }
        />
      </BetweenWrapper>

      <Table
        data={usersForTable}
        error={isError}
        loading={isLoading}
        empty={(usersForTable || []).length === 0}
        columns={[
          { title: 'Email', field: 'id', flex: 2, wrapOverflow: false },
          { title: 'Created At', field: 'createdAt', flex: 2 },
          { title: 'Last Login', field: 'lastLogin', flex: 2 },
          { title: 'Status', field: 'status', flex: 1, isText: false },
          {
            title: 'Read+Write Privileges',
            field: 'readAndWritePrivileges',
            flex: 3,
            wrapOverflow: false,
          },
          {
            title: 'Read-Only Privileges',
            field: 'readOnlyPrivileges',
            flex: 3,
            wrapOverflow: false,
          },
          { title: 'Edit', field: 'action', flex: 1, isText: false },
        ]}
        width="100%"
        height="fit-content"
      />
      {lastPage > 0 && (
        <TablePagination
          curPageNumber={pageNumber}
          lastPageNumber={lastPage}
          setCurPageNumber={setPageNumber}
        />
      )}
    </UsersCard>
  );
}
