import { TableStatusText } from '@/shared/components/TableComponents';
import { colors } from '@/shared/styles';
import { TaxesStatus } from '@/shared/types';

export const TAX_YEARS = [2023] as const;

export const taxesStatusRenderMap: Record<TaxesStatus, JSX.Element> = {
  NOT_STARTED: <TableStatusText color={colors.textGrey}>Not started</TableStatusText>,
  CREATED: <TableStatusText color={colors.trueBlack}>Created</TableStatusText>,
  FILED: <TableStatusText color={colors.accentTeal}>Filed</TableStatusText>,
  ACCEPTED: <TableStatusText color={colors.accentGreen}>Accepted</TableStatusText>,
  REJECTED: <TableStatusText color={colors.accentRed}>Rejected</TableStatusText>,
};
