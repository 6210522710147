/**
 * Sidebar for navigating between views in the Customer Dashboard
 */
import SidebarContainer from './SidebarContainer';

import Logo from '@/shared/assets/logo.svg';
import { Column, LogoImg } from '@/shared/components/Sidebar.styled';
import SidebarLink, { SidebarItemT } from './SidebarItem';

import { CUSTOMER_SUPPORT_URL } from '@/shared/constants';
import { FeatureToggles } from '@/shared/feature-toggles/FeatureToggles';
import { colors } from '@/shared/styles';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { CustomerAuthMenu } from './CustomerAuthMenu';

const FlexColumn = styled(Column)`
  justify-content: space-between;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding-left: 12px;
  padding-right: 12px;
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
`;

const LogoContainer = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const glowingEffect = keyframes`
  0% { border: 2px solid ${colors.accentTeal}; }
  50% { border: 2px solid ${colors.accentGreen}; }
  100% { border: 2px solid ${colors.accentTeal}; }
`;

const NewInterfaceBlock = styled.div`
  padding: 8px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  font-size: 14px;
  animation: ${glowingEffect} 4s infinite;
`;

type Props = {
  items: SidebarItemT[];
};

const Sidebar: FC<Props> = ({ items }) => {
  const { pathname } = useLocation();

  return (
    <>
      <FeatureToggles />
      <SidebarContainer>
        <FlexColumn>
          <MainContent>
            <LogoContainer>
              <LogoImg src={Logo} alt="Logo" height={32} />
            </LogoContainer>

            {items.map((i) => (
              <SidebarLink
                key={i.to}
                text={i.text}
                to={i.to}
                icon={i.icon}
                disabled={i?.disabled ?? false}
                active={pathname === i.to}
              />
            ))}
          </MainContent>
          <BottomContent>
            <SidebarLink
              text="Need help?"
              onClick={() => {
                window.open(CUSTOMER_SUPPORT_URL, '_blank', 'noopener,noreferrer');
              }}
              icon="help-circle"
            />
            <NewInterfaceBlock>🎉 Improved dashboard experience coming soon...</NewInterfaceBlock>
            <CustomerAuthMenu />
          </BottomContent>
        </FlexColumn>
      </SidebarContainer>
    </>
  );
};
export default Sidebar;
