import { useCustomer, useCustomerProfilePermissions } from '@/services/useCustomerProfile';
import { PageLayout } from '@/shared/components/PageLayout/PageLayout';
import { useGetFeatureToggle } from '@/shared/feature-toggles/feature-toggles';
import { TabPermissions } from '@/shared/types';
import styled from 'styled-components';
import TaxesBatchFileTable from './TaxesBatchFileTable';
import { TaxesTable } from './TaxesTable';

const ZeroStateContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 256px;
`;

const TitleText = styled.p`
  font-size: 32px;
`;

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-bottom: 24px;
`;

const SupportText = styled.a`
  font-size: 12px;
  opacity: 50%;
  text-decoration: underline;
  :hover {
    font-size: 12px;
    opacity: 80%;
    text-decoration: underline;
  }
  :focus {
    font-size: 12px;
    opacity: 80%;
    text-decoration: underline;
  }
`;

export default function CustomerTaxesTab() {
  const { data: permissions } = useCustomerProfilePermissions();
  const { data: customer } = useCustomer();
  const hasWritePermissions = permissions?.workersTab === TabPermissions.write;

  const showNewUi = useGetFeatureToggle('showNewUi');

  if (customer?.featureFlags?.taxesDisabled.enabled) {
    return (
      <ZeroStateContainer>
        <BlockContainer>
          <TitleText>You currently have Taxes disabled on your account</TitleText>
        </BlockContainer>

        <BlockContainer>
          <p>Generate 1099-NECs, file, review, and request corrections,</p>
          <p>all available here on your dashboard.</p>
        </BlockContainer>
        <SupportText href="https://customersupport.checkrpay.com/" target="_blank">
          Reach out to get taxes enabled on your account.
        </SupportText>
      </ZeroStateContainer>
    );
  }

  if (showNewUi) {
    return (
      <PageLayout title="Taxes">
        {hasWritePermissions && <TaxesBatchFileTable />}
        <TaxesTable />
        {/* Extra div at the end for bottom spacing */}
        <div />
      </PageLayout>
    );
  }

  return (
    <>
      {hasWritePermissions && <TaxesBatchFileTable />}
      <TaxesTable />
      {/* Extra div at the end for bottom spacing */}
      <div />
    </>
  );
}
