import { TaxYears } from '@/pages/customer-dash/taxes/taxes.types';
import { Taxes } from '@/shared/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import serverApi from './serverApi';

type BaseTaxesReq = {
  workerProfileId: string;
  year: number;
};

type TaxesWithPreviousIncomeReq = BaseTaxesReq & {
  nonPlatformEarningsAmount: number;
};

const postCreateOneTaxes = async ({
  workerProfileId,
  year,
  nonPlatformEarningsAmount,
}: TaxesWithPreviousIncomeReq) => {
  const response = await serverApi.post(`/dashboard/v0/taxes/1099-nec/${workerProfileId}/${year}`, {
    nonPlatformEarningsAmount,
  });
  return response.data;
};

const postFileOneTaxes = async ({ workerProfileId, year }: BaseTaxesReq) => {
  const response = await serverApi.post(
    `/dashboard/v0/taxes/1099-nec/${workerProfileId}/${year}/file`
  );
  return response.data;
};

export const fetchOneTaxes = async ({ workerProfileId, year }: BaseTaxesReq) => {
  const response = await serverApi.get<Taxes>(
    `/dashboard/v0/taxes/1099-nec/${workerProfileId}/${year}`
  );
  return response.data;
};

type BulkFileReq = {
  workerProfileIds: string[];
  year: TaxYears;
};

export type BulkFileResp = {
  workerProfileId: string;
  status: 'OK' | 'ERROR';
  errMessage: string | undefined;
  updated1099nec?: unknown | undefined;
};

const postBulkFileTaxes = async (body: BulkFileReq) => {
  const response = await serverApi.post<BulkFileResp[]>(
    '/dashboard/v0/taxes/1099-nec/bulk_file',
    body
  );
  return response.data;
};

export const useGetOneTaxes = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ workerProfileId, year }: BaseTaxesReq) =>
      fetchOneTaxes({ workerProfileId, year }),
    onSuccess: () => {
      queryClient.invalidateQueries(['customer', 'workers']);
    },
  });
};

export const postRequestCorrection = async ({
  workerProfileId,
  year,
  nonPlatformEarningsAmount,
}: TaxesWithPreviousIncomeReq) => {
  const response = await serverApi.post(
    `/dashboard/v0/taxes/1099-nec/${workerProfileId}/${year}/correction`,
    { nonPlatformEarningsAmount }
  );
  return response.data;
};

export const useCreateOneTaxes = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      workerProfileId,
      year,
      nonPlatformEarningsAmount,
    }: TaxesWithPreviousIncomeReq) =>
      postCreateOneTaxes({ workerProfileId, year, nonPlatformEarningsAmount }),
    onSuccess: () => {
      queryClient.invalidateQueries(['customer', 'workers']);
    },
  });
};

export const useFileOneTaxes = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ workerProfileId, year }: BaseTaxesReq) =>
      postFileOneTaxes({ workerProfileId, year }),
    onSuccess: () => {
      queryClient.invalidateQueries(['customer', 'workers']);
    },
  });
};

export const useRequestCorrection = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      workerProfileId,
      year,
      nonPlatformEarningsAmount,
    }: TaxesWithPreviousIncomeReq) =>
      postRequestCorrection({ workerProfileId, year, nonPlatformEarningsAmount }),
    onSuccess: () => {
      queryClient.invalidateQueries(['customer', 'workers']);
    },
  });
};

export const useBulkFileTaxes = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ workerProfileIds, year }: BulkFileReq) =>
      postBulkFileTaxes({ workerProfileIds, year }),
    onSuccess: () => {
      queryClient.invalidateQueries(['customer', 'workers']);
    },
  });
};
