import { Dropdown } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';
import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import DateRangePicker from './DateRangePicker';

type DateSelectionRange =
  | 'allTime'
  | 'lastMonth'
  | 'last3Months'
  | 'last6Months'
  | 'lastWeek'
  | 'last30Days'
  | 'custom';

type Props = {
  allTimeStartDate: Date;
  defaultStartDate: Date;
  defaultEndDate: Date;
  onChange: (range: DateSelectionRange, startDate: Date, endDate: Date) => void;
};

const DateRangeSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const DateRangeSelector: FC<Props> = ({ allTimeStartDate, defaultStartDate, onChange }) => {
  const [customStartDate, setCustomStartDate] = useState(dayjs(defaultStartDate).toDate());
  const [customEndDate, setCustomEndDate] = useState(dayjs().add(1, 'day').toDate());
  const [dateRange, setDateRange] = useState<DateSelectionRange>('lastWeek');

  const dateSelectionRanges = useMemo(
    () => ({
      allTime: {
        startDate: dayjs(allTimeStartDate).toDate(),
        endDate: dayjs().add(1, 'day').toDate(),
      },
      lastMonth: {
        startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
        endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
      },
      last3Months: {
        startDate: dayjs().subtract(3, 'month').startOf('day').toDate(),
        endDate: dayjs().endOf('day').toDate(),
      },
      last6Months: {
        startDate: dayjs().subtract(6, 'month').startOf('day').toDate(),
        endDate: dayjs().endOf('day').toDate(),
      },
      lastWeek: {
        startDate: dayjs().subtract(1, 'week').startOf('day').toDate(),
        endDate: dayjs().endOf('day').toDate(),
      },
      last30Days: {
        startDate: dayjs().subtract(30, 'day').startOf('day').toDate(),
        endDate: dayjs().endOf('day').toDate(),
      },
      custom: {
        startDate: customStartDate,
        endDate: customEndDate,
      },
    }),
    [allTimeStartDate, customStartDate, customEndDate]
  );

  return (
    <DateRangeSelectorWrapper>
      <Dropdown
        options={[
          // { label: 'All time', value: 'allTime' },
          { label: 'Last week', value: 'lastWeek' },
          { label: 'Last month', value: 'lastMonth' },
          { label: 'Last 3 months', value: 'last3Months' },
          { label: 'Last 6 months', value: 'last6Months' },
          { label: 'Last 30 days', value: 'last30Days' },
          { label: 'Custom', value: 'custom' },
        ]}
        closeOnChange
        closeOnOutsideClick
        onSelect={(opt) => {
          const selectedRange = dateSelectionRanges[opt.value as keyof typeof dateSelectionRanges];
          setCustomStartDate(selectedRange.startDate);
          setCustomEndDate(selectedRange.endDate);
          setDateRange(opt.value as DateSelectionRange);
          onChange(opt.value as DateSelectionRange, selectedRange.startDate, selectedRange.endDate);
        }}
      />
      {dateRange === 'custom' && (
        <DateRangePicker
          defaultStartDate={customStartDate}
          defaultEndDate={customEndDate}
          onChange={(dates) => {
            const [d1, d2] = dates;
            setCustomStartDate(d1);
            setCustomEndDate(d2);
            onChange(dateRange, d1, d2);
          }}
        />
      )}
    </DateRangeSelectorWrapper>
  );
};
