/**
 * Top-Bar for the Customer View
 */
import {
  customerViewToTitle,
  pathToCustomerView,
} from '@/pages/customer-dash/customer-dash-helpers';
import { colors } from '@/shared/styles';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const TopBar = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px 30px;
  border-bottom: 1px solid ${colors.dividerGrey};
  box-shadow: 3px 1px 6px -2px rgba(0, 0, 0, 0.05);
`;

const TopBarTitle = styled.h1`
  font-size: 1.875rem;
  font-weight: 600;
  color: ${colors.primaryBlue};
  line-height: 1;
`;

function CustomerTopbar() {
  const { pathname } = useLocation();
  const title = customerViewToTitle(pathToCustomerView(pathname));

  return (
    <TopBar>
      <TopBarTitle>{title}</TopBarTitle>
    </TopBar>
  );
}

export default CustomerTopbar;
