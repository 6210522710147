import dayjs from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';

import { CardTitle, ContentCard } from '@/shared/components/Card';
import { Dropdown, Modal, Table } from '@checkrx/pay-component-library';

import { useBatchCsvReturnFile, useBatchFiles } from '@/services/useBatchFiles';
import { ReactComponent as BatchUploadIcon } from '@/shared/assets/batch-upload.svg';
import { ReactComponent as DownloadIcon } from '@/shared/assets/download-icon.svg';
import { BatchFileHelpLink } from '@/shared/components/BatchFileHelpLink';
import BatchFileUpload from '@/shared/components/BatchFileUpload';
import { BatchUploadIconButton, BatchUploadIconText } from '@/shared/components/BatchUpload.styled';
import DateRangePicker from '@/shared/components/DateRangePicker';
import { DownloadButton } from '@/shared/components/DownloadButton.styled';
import { batchFileStatusToDisplay, ControlsRow } from '@/shared/components/TableComponents';
import { TablePagination } from '@/shared/components/TablePagination';
import { BatchFileStatus, BatchFileType } from '@/shared/types';

const TaxesBatchFileTableCard = styled(ContentCard)`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  min-height: fit-content;
  gap: 30px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-top: -55px;
`;

const BATCH_FILES_PER_PAGE = 5;

export default function TaxesBatchFileTable() {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'week').toDate());
  const [endDate, setEndDate] = useState(dayjs().add(1, 'day').toDate());
  const [pageNumber, setPageNumber] = useState(0);

  // Filtering state
  const [status, setStatus] = useState<BatchFileStatus | ''>('');

  // Modal State Tracking
  const [batchModalOpen, setBatchModalOpen] = useState(false);

  const {
    data: fetchedBatchFiles,
    isLoading: batchFilesIsLoading,
    isError: batchFilesIsError,
  } = useBatchFiles(
    startDate,
    endDate,
    BatchFileType.Taxes1099,
    status !== '' ? status : undefined
  );

  const { mutateAsync: createCsvReturnFileAsync } = useBatchCsvReturnFile();

  const batchFileActionToDisplay = (
    status: BatchFileStatus | null,
    batchFileId: string,
    batchFileName: string
  ) => {
    if (
      status &&
      [BatchFileStatus.Success, BatchFileStatus.Error, BatchFileStatus.ManualReview].includes(
        status
      )
    ) {
      return (
        <DownloadButton onClick={() => createCsvReturnFileAsync({ batchFileId, batchFileName })}>
          <DownloadIcon />
          <p>Download CSV</p>
        </DownloadButton>
      );
    }
    return <></>;
  };

  const startIdx = pageNumber * BATCH_FILES_PER_PAGE;
  const endIdx = (pageNumber + 1) * BATCH_FILES_PER_PAGE;
  const batchFilesForTable = (fetchedBatchFiles || []).slice(startIdx, endIdx).map((batchFile) =>
    // Map fetched batchFiles to displayable data
    ({
      id: batchFile.inputFileName,
      date: dayjs(batchFile.createdAt).format('D MMM YYYY, h:mma'),
      status: batchFileStatusToDisplay(batchFile?.status),
      uploader: batchFile?.customerProfile?.email || 'Admin',
      successCount: batchFile?.successCount,
      failureCount: batchFile?.failureCount,
      action: batchFileActionToDisplay(batchFile?.status, batchFile?._id, batchFile?.inputFileName),
    })
  );
  const lastPage = Math.floor((fetchedBatchFiles || []).length / BATCH_FILES_PER_PAGE);

  return (
    <TaxesBatchFileTableCard>
      <Modal
        isOpen={batchModalOpen}
        close={() => {
          setBatchModalOpen(false);
        }}
        headerText="Generate 1099-NEC Batch File"
        subHeaderText="Create multiple 1099-NEC forms with a single click"
        modalContent={<BatchFileUpload type={BatchFileType.Taxes1099} />}
      />
      <CardTitle>Batch 1099-NEC Generation</CardTitle>
      <HeaderContainer>
        <ControlsRow>
          <DateRangePicker
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            onChange={(dates) => {
              const [d1, d2] = dates;
              setStartDate(d1);
              setEndDate(d2);
            }}
          />
          <Dropdown
            options={[
              { label: 'Any Status', value: '' },
              { label: 'Pending', value: BatchFileStatus.Pending },
              { label: 'Started', value: BatchFileStatus.Started },
              { label: 'Completed', value: BatchFileStatus.Success },
              { label: 'Manual Review', value: BatchFileStatus.ManualReview },
              { label: 'Error', value: BatchFileStatus.Error },
            ]}
            closeOnChange
            closeOnOutsideClick
            onSelect={(opt) => setStatus(opt.value as BatchFileStatus)}
          />
        </ControlsRow>
        <BatchFileHelpLink>
          <BatchUploadIconButton
            type="button"
            onClick={() => {
              setBatchModalOpen(true);
            }}
          >
            <BatchUploadIcon />
            <BatchUploadIconText>Batch Upload</BatchUploadIconText>
          </BatchUploadIconButton>
        </BatchFileHelpLink>
      </HeaderContainer>
      <Table
        data={batchFilesForTable}
        error={batchFilesIsError}
        loading={batchFilesIsLoading}
        empty={(batchFilesForTable || []).length === 0}
        columns={[
          { title: 'File Name', field: 'id', flex: 3, wrapOverflow: false },
          { title: 'Date', field: 'date', flex: 2 },
          { title: 'Status', field: 'status', flex: 2, isText: false },
          { title: 'Uploader', field: 'uploader', flex: 2, isText: true },
          {
            title: 'Number Success',
            field: 'successCount',
            flex: 2,
            isText: true,
          },
          {
            title: 'Number Failures',
            field: 'failureCount',
            flex: 2,
            isText: true,
          },
          { title: 'Actions', field: 'action', flex: 2, isText: false },
        ]}
        width="100%"
      />
      {lastPage > 0 && (
        <TablePagination
          curPageNumber={pageNumber}
          lastPageNumber={lastPage}
          setCurPageNumber={setPageNumber}
        />
      )}
    </TaxesBatchFileTableCard>
  );
}
