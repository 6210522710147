import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as BatchUploadIcon } from '@/shared/assets/batch-upload.svg';
import BatchFileUpload from '@/shared/components/BatchFileUpload';
import { BatchUploadIconButton, BatchUploadIconText } from '@/shared/components/BatchUpload.styled';
import { CardTitle, ContentCard } from '@/shared/components/Card';
import ExplainerCircle from '@/shared/components/ExplainerCircle';
import { BatchFileType } from '@/shared/types';
import { isCandidateId, isCsvSafeString, isEmailAddress, isPhoneNumber } from '@/shared/validation';
import { Button, Modal, TextArea, TextInput } from '@checkrx/pay-component-library';
import WorkerConfirmationModal from './WorkerConfirmationModal';

import { textareaStripper } from '@/services/textarea-stripper';
import { BatchFileHelpLink } from '@/shared/components/BatchFileHelpLink';
import {
  ControlsCol,
  ControlsRow,
  CreateOneCol,
  ErrorText,
  LabelRow,
  LabelText,
  SubmitButtonBox,
} from '@/shared/components/CreationComponents';
import { useGetFeatureToggle } from '@/shared/feature-toggles/feature-toggles';

const CreateWorkersCardWrapper = styled(ContentCard)`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  min-height: fit-content;

  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`;

export default function CreateWorkersCard() {
  const [candidateId, setCandidateId] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [metadata, setMetadata] = useState<string | null>(null);

  // Track error messages for each input
  const [candidateIdErr, setCandidateIdErr] = useState<string | null>(null);
  const [emailErr, setEmailErr] = useState<string | null>(null);
  const [phoneNumberErr, setPhoneNumberErr] = useState<string | null>(null);
  const [metadataErr, setMetadataErr] = useState<string | null>(null);

  // Modal State for Batch Files
  const [batchModalOpen, setBatchModalOpen] = useState(false);
  const [confirmWorkerModalOpen, setConfirmWorkerModalOpen] = useState(false);

  const ignoreWorkerCreationValidation = useGetFeatureToggle('ignore worker creation validation');

  // Clear state if we succeed at creating the resource
  function clear() {
    setEmail(null);
    setCandidateId(null);
    setFirstName(null);
    setLastName(null);
    setPhoneNumber(null);
    setMetadata(null);
  }

  const validateInputs = () => {
    let canSubmit = true;

    // Check Email
    if ((email || '').length < 1) {
      canSubmit = false;
      setEmailErr('Worker email is blank. Please enter worker email.');
    } else if (!isEmailAddress(email)) {
      canSubmit = false;
      setEmailErr('Worker email is invalid. Please enter valid worker email.');
    } else {
      setEmailErr(null);
    }

    // Check Phone Number
    if ((phoneNumber || '').length < 1) {
      canSubmit = false;
      setPhoneNumberErr('Worker phone number is blank. Please enter worker phone number.');
    } else if (!isPhoneNumber(phoneNumber)) {
      canSubmit = false;
      setPhoneNumberErr('Worker phone number is invalid. Please enter valid worker phone number.');
    } else {
      setPhoneNumberErr(null);
    }

    // Check Candidate Id
    if ((candidateId || '').length > 0) {
      if (!isCandidateId(candidateId)) {
        canSubmit = false;
        setCandidateIdErr('Checkr Candidate Id is invalid. Please enter valid candidate Id.');
      }
    }

    // Optional Metadata
    if (metadata && metadata.length > 0) {
      if (metadata.length > 256) {
        canSubmit = false;
        setMetadataErr('Metadata cannot be more than 256 characters');
      } else if (!isCsvSafeString(metadata)) {
        canSubmit = false;
        setMetadataErr('Metadata cannot begin with special characters +, -, =, or @');
      } else {
        setMetadataErr(null);
      }
    } else {
      setMetadataErr(null);
    }
    return canSubmit;
  };

  const onSubmitOneWorker = async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const canProceed = ignoreWorkerCreationValidation ? true : validateInputs();
    if (canProceed) {
      // Clear the error messages
      if (email && phoneNumber) {
        setConfirmWorkerModalOpen(true);
      }
    }
  };

  // Listen for "Enter" key throughout the form
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      onSubmitOneWorker(event);
    }
  };

  const strippedMetadata = textareaStripper(metadata ?? '');

  return (
    <CreateWorkersCardWrapper onKeyDown={onKeyDown}>
      <Modal
        isOpen={confirmWorkerModalOpen}
        close={() => {
          setConfirmWorkerModalOpen(false);
        }}
        headerText="Confirm Worker Info"
        subHeaderText="Double check this information before creating your worker"
        modalContent={
          // NOTE(Carter): Null assertion is fine here because we validate above
          <WorkerConfirmationModal
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            candidateId={candidateId!}
            firstName={firstName || undefined}
            lastName={lastName || undefined}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            email={email!}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            phoneNumber={phoneNumber!}
            clearParentFn={clear}
            metadata={metadata ? strippedMetadata : undefined}
          />
        }
      />
      <Modal
        isOpen={batchModalOpen}
        close={() => {
          setBatchModalOpen(false);
        }}
        headerText="Upload Worker Batch File"
        subHeaderText="Create multiple workers with a single click"
        modalContent={<BatchFileUpload type={BatchFileType.Worker} />}
      />
      <CreateOneCol>
        <CardTitle>Add Worker</CardTitle>
        <ControlsRow>
          <ControlsCol>
            <LabelRow invalid={!!emailErr}>
              <LabelText>Email</LabelText>
              <LabelText>*</LabelText>
            </LabelRow>
            <TextInput
              width="280px"
              placeholder="Worker's Email Address"
              height="60px"
              value={email ?? undefined}
              invalid={!!emailErr}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {emailErr && <ErrorText maxWidth="280px">{emailErr}</ErrorText>}
          </ControlsCol>
          <ControlsCol>
            <LabelRow invalid={!!phoneNumberErr}>
              <LabelText>Phone Number</LabelText>
              <LabelText>*</LabelText>
            </LabelRow>
            <TextInput
              width="280px"
              placeholder="Worker's Phone Number"
              height="60px"
              value={phoneNumber ?? undefined}
              invalid={!!phoneNumberErr}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
            {phoneNumberErr && <ErrorText maxWidth="280px">{phoneNumberErr}</ErrorText>}
          </ControlsCol>
        </ControlsRow>
        <ControlsRow>
          <ControlsCol>
            <LabelRow>
              <LabelText>Candidate ID</LabelText>
              <ExplainerCircle
                text={
                  'You can optionally include a Checkr Core Candidate ID for new workers. ' +
                  'Doing so will prefill their name, DOB, and SSN from their last Checkr report.'
                }
                direction="right"
              />
            </LabelRow>
            <TextInput
              width="280px"
              placeholder="Worker's Checkr Candidate ID"
              height="60px"
              value={candidateId ?? undefined}
              onChange={(e) => {
                setCandidateId(e.target.value);
              }}
            />
            {candidateIdErr && <ErrorText maxWidth="280px">{candidateIdErr}</ErrorText>}
          </ControlsCol>
          <ControlsCol>
            <LabelRow>
              <LabelText>First Name</LabelText>
            </LabelRow>
            <TextInput
              width="280px"
              placeholder="First Name"
              height="60px"
              value={firstName ?? undefined}
              onChange={(e) => {
                setFirstName((e.target.value || '').trim());
              }}
            />
          </ControlsCol>
          <ControlsCol>
            <LabelRow>
              <LabelText>Last Name</LabelText>
            </LabelRow>
            <TextInput
              width="280px"
              placeholder="Last Name"
              height="60px"
              value={lastName ?? undefined}
              onChange={(e) => {
                setLastName((e.target.value || '').trim());
              }}
            />
          </ControlsCol>
        </ControlsRow>
        <ControlsRow>
          <ControlsCol>
            <LabelRow invalid={!!metadataErr}>
              <LabelText>Optional Metadata</LabelText>
            </LabelRow>

            <TextArea
              width="700px"
              height="100px"
              placeholder="Optional Worker Metadata"
              maxLength={256}
              value={metadata ?? undefined}
              invalid={!!metadataErr}
              onChange={(e) => {
                setMetadata(e.target.value);
              }}
            />
            {metadataErr && <ErrorText maxWidth="700px">{metadataErr}</ErrorText>}
          </ControlsCol>
          <SubmitButtonBox>
            <Button
              text="Submit"
              sizeVariant="big"
              colorVariant="brand"
              width="155px"
              onClick={onSubmitOneWorker}
            />
          </SubmitButtonBox>
        </ControlsRow>
      </CreateOneCol>
      <BatchFileHelpLink>
        <BatchUploadIconButton
          type="button"
          onClick={() => {
            setBatchModalOpen(true);
          }}
        >
          <BatchUploadIcon />
          <BatchUploadIconText>Batch Upload</BatchUploadIconText>
        </BatchUploadIconButton>
      </BatchFileHelpLink>
    </CreateWorkersCardWrapper>
  );
}
