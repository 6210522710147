import { toast } from '@/app/wrappers/Toaster/Toaster';
import { useExchangeProfile } from '@/services/login';
import { useCustomerProfile } from '@/services/useCustomerProfile';
import { useDocumentEvent } from '@/shared/hooks/useDocumentEvent';
import useLoading from '@/shared/hooks/useLoading';
import { colors } from '@/shared/styles';
import { CustomerProfile, LoadingScope } from '@/shared/types';
import { Icon } from '@checkrx/pay-component-library';
import { captureException } from '@sentry/browser';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Tether from '../MenuButton/Tether';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border: 1px solid ${colors.dividerGrey};
  border-radius: 6px;
  padding-left: 12px;
  padding-right: 12px;
  background: ${colors.trueWhite};
`;

const CustomerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 70%;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 4px;
`;

const CustomerName = styled.div`
  color: ${colors.primaryBlue};
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserEmail = styled.div`
  color: ${colors.labelGrey};
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const SwitcherContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  width: 216px;
  background-color: white;
  max-height: 240px;
  border: 1px solid ${colors.dividerGrey};
  border-radius: 6px;
  padding: 6px;
`;

const SwitcherTitle = styled.div`
  width: 100%;
  color: ${colors.labelGrey};
  font-size: 14px;
  margin-left: 6px;
`;

const SwitcherOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-height: 212px;
  overflow-y: auto;
`;

const SwitcherOption = styled.div`
  width: 100%;
  min-height: 24px;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 14px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${colors.dividerGrey};
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CustomerAuthMenu = () => {
  const setLoading = useLoading(false, LoadingScope.global);
  const { mutateAsync: exchangeProfile } = useExchangeProfile();
  const [showProfileSwitcher, setShowProfileSwitcher] = useState(false);
  const { data: customerProfile } = useCustomerProfile();
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useDocumentEvent('click', (e) => {
    if (
      showProfileSwitcher &&
      containerRef.current &&
      !containerRef.current.contains(e.target as Node) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setShowProfileSwitcher(false);
    }
  });
  const altProfiles = customerProfile?.altProfiles || [];
  const profileOptions = altProfiles.map((p) => ({
    label: p.customer.humanReadableName,
    value: p,
  }));

  const handleSwitchProfile = async (altProfile: CustomerProfile['altProfiles'][number]) => {
    try {
      setShowProfileSwitcher(false);
      setLoading(true);
      await exchangeProfile(altProfile.customer._id);
      setLoading(false);
      toast({
        message: `Signed in to ${altProfile.customer.humanReadableName}`,
        type: 'success',
        duration: 2000,
      });
    } catch (err) {
      captureException(err);
      toast({
        message: 'Unexpected error while switching profiles',
        type: 'error',
        duration: 5000,
      });
    }
  };

  const switcherContent = (
    <SwitcherContainer>
      <SwitcherTitle>Switch profiles</SwitcherTitle>
      <SwitcherOptionsContainer>
        {profileOptions.map((p) => (
          <SwitcherOption key={p.value._id} onClick={() => handleSwitchProfile(p.value)}>
            {p.label}
          </SwitcherOption>
        ))}
      </SwitcherOptionsContainer>
    </SwitcherContainer>
  );
  return (
    <>
      <Container ref={containerRef}>
        <CustomerInfo>
          <CustomerName title={customerProfile?.customer.humanReadableName}>
            {customerProfile?.customer.humanReadableName}
          </CustomerName>
          <UserEmail title={customerProfile?.email}>{customerProfile?.email}</UserEmail>
        </CustomerInfo>
        <IconContainer>
          {(customerProfile?.altProfiles?.length ?? 0) > 0 && (
            <IconWrapper onClick={() => setShowProfileSwitcher((curr) => !curr)}>
              <Icon name="menu" color={showProfileSwitcher ? 'primary' : 'black'} />
            </IconWrapper>
          )}
          <IconWrapper onClick={() => navigate('/customer/logout')} data-testid="logout-button">
            <Icon name="log-out" color="error" />
          </IconWrapper>
        </IconContainer>
      </Container>
      <Tether target={containerRef} align="top" show={showProfileSwitcher} offset={{ vertical: 8 }}>
        {switcherContent}
      </Tether>
    </>
  );
};
