import App from '@/app/App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import BaseErrorFallback from './app/wrappers/BaseErrorFallback';

// Initialize sentry and analytics
import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { initAnalytics } from './analytics';
import { Toaster } from './app/wrappers/Toaster/Toaster';

Sentry.init({
  allowUrls: [/checkrpay\.com/], // ignore errors from 3rd party scripts like fullview
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  ignoreErrors: [/NetworkError/], // ignore network errors
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
});

initAnalytics();

// import { startReportingRuntimeErrors } from 'react-error-overlay';

// console.log('In index');
// console.log(process.env.NODE_ENV);
// if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'development') {
//   console.log('Trying to report...');
//   startReportingRuntimeErrors(); // enables error overlays
// }

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // By default, consider data active for 30 seconds
      staleTime: 30000,
      // By default, a refresh doesn't force pulling stale data
      refetchOnMount: 'always',
      // Retry once automatically if a network request fails
      retry: 1,
      // Retry after 500ms
      retryDelay: 500,
    },
    mutations: {
      // Retry after 500ms
      retryDelay: 500,
    },
  },
});
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={BaseErrorFallback}>
      <QueryClientProvider client={queryClient}>
        <App />
        <Toaster />
        {/*
         * React Query Dev Tools – for debugging / inspection, change initialIsOpen to true.
         * NOTE: This is not bundled in production by default, so no concerns
         */}
        <ReactQueryDevtools initialIsOpen={false} position="top-right" />
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
