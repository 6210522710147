import DollarAmount from '@/shared/components/DollarAmount';
import { Button, Spinner } from '@checkrx/pay-component-library';

import { toast } from '@/app/wrappers/Toaster/Toaster';
import { useFileOneTaxes } from '@/services/useWorkerTaxes';
import {
  ContentWrapper,
  DescriptionText,
  LabelRow,
  LabelText,
  ValueText,
} from '@/shared/components/ConfirmationModals.styled';
import styled from 'styled-components';
import { TaxYears, WorkerWithEarnings } from './taxes.types';

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 24px;
  margin-top: 16px;
`;

type ConfirmFileModalProps = {
  worker: WorkerWithEarnings;
  taxYear: TaxYears;
  onClose: () => void;
  onViewPdf: (worker: WorkerWithEarnings) => void;
};

const ConfirmFileModal = ({ worker, taxYear, onClose, onViewPdf }: ConfirmFileModalProps) => {
  const { mutateAsync: fileOneTax, isLoading: fileLoading } = useFileOneTaxes();

  const handleNewFiling = async () => {
    try {
      await fileOneTax({ workerProfileId: worker.id, year: taxYear });
      onClose();
      toast({
        message: `Filed taxes for ${worker.profile?.legalName}`,
        type: 'success',
        duration: 5000,
      });
    } catch (err) {
      toast({
        message: `Failed to file taxes for ${worker.profile?.legalName}`,
        type: 'error',
        duration: 5000,
      });
    }
  };

  return (
    <ContentWrapper>
      <DescriptionText>
        Please verify the information before proceeding with the filing
      </DescriptionText>
      <LabelRow>
        <LabelText>Earner:</LabelText>
        <ValueText>{worker.profile?.legalName}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Earnings in Checkr Pay:</LabelText>
        <DollarAmount amountCents={worker.earnedCentsTotal} />
      </LabelRow>
      <LabelRow>
        <LabelText>External Earnings:</LabelText>
        <DollarAmount amountCents={Number(worker?.['1099nec']?.[taxYear]?.nonPlatformIncome)} />
      </LabelRow>
      <ButtonRow>
        <Button text="View pdf" sizeVariant="big" width="155px" onClick={() => onViewPdf(worker)} />
        <Button
          text="File"
          sizeVariant="big"
          colorVariant="brand"
          width="155px"
          onClick={handleNewFiling}
        />
        {fileLoading && <Spinner size="30px" />}
      </ButtonRow>
    </ContentWrapper>
  );
};

export default ConfirmFileModal;
