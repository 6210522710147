import { DateString } from './types';
import { Worker } from './worker.types';

export enum PayoutStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Queued = 'queued',
}

export type Payout = {
  amountCents: number;
  attemptedQueuedPayout: boolean;
  completedAt: DateString;
  createdAt: DateString;
  description: string;
  expectedCompletionDate?: string;
  feeCents: number;
  groups?: {
    [key: string]: string;
  };
  id: string;
  metadata?: string;
  object?: 'payout';
  repayments?: string[];
  status: PayoutStatus;
  workerId: Worker['id'];
  workerResource?: Pick<Worker, 'createdAt' | 'id' | 'profile'>;
};
