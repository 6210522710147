/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * validation.ts - Short reusable functions to help us clean and validate inputs from the Customer.
 *
 * Need:
 *   - Email
 *   - Background Check ID
 *   - Max Length
 *   - Min Length
 *   - Number (amount)
 *   - Phone Number
 *   - Worker Id
 *
 */
import plainJoi, { CustomHelpers } from 'joi';
import joiPhoneNumber from 'joi-phone-number';
import {
  BatchFileType,
  PayoutBatchData,
  PayoutGroupDefinition,
  Taxes1099BatchData,
  WorkerBatchData,
} from './types';

const phoneJoi = plainJoi.extend(joiPhoneNumber);

// Custom Joi extension for csv Injection prevention.
const Joi = phoneJoi.extend({
  type: 'string',
  base: phoneJoi.string(),
  messages: {
    'string.csvInjection': '{{#label}} must not start with +, =, -, or @',
  },
  rules: {
    csvInjection: {
      validate(value: string, helpers: CustomHelpers) {
        // Check if the string starts with forbidden character
        if (/^[+=\-@]/.test(value)) {
          return helpers.error('string.csvInjection');
        }

        return value; // Return the value if validation passes
      },
    },
  },
});

const phoneNumberSchema = Joi.string().phoneNumber({
  defaultCountry: 'US',
  strict: true, // Fail on non-US phone numbers
  format: 'e164',
});

// True or false – does it work?
function checkValidation(schema: any, obj: any) {
  const { error, value } = schema.validate(obj, {
    abortEarly: true,
  });
  if (error || !value) {
    return false;
  } else {
    return true;
  }
}

export function isPhoneNumber(number?: any) {
  return checkValidation(phoneNumberSchema, number);
}

export function isEmailAddress(email?: any) {
  return checkValidation(Joi.string().email({ tlds: { allow: false } }), email);
}

export function isCandidateId(candidateId?: any) {
  return checkValidation(Joi.string().alphanum().min(6).max(128), candidateId);
}

export function isCsvSafeString(arbitraryString?: any) {
  return checkValidation(Joi.string().csvInjection(), arbitraryString);
}

export function isWorkerId(workerId?: any) {
  return checkValidation(
    // Should be 36-character Identifier with dashes
    Joi.string()
      .regex(/^[a-zA-Z0-9-]*$/)
      .min(36)
      .max(36),
    workerId
  );
}

export function isAmount(amount?: any) {
  return checkValidation(Joi.number().integer().positive(), amount);
}

// Some functions to validate CSV format and values and return user-friendly error messages
export const batchFileRequiredHeaders = {
  [BatchFileType.Worker]: ['piiType', 'phoneNumber'],
  [BatchFileType.Payout]: ['workerIdType', 'amountCents', 'description'],
  [BatchFileType.Taxes1099]: ['workerIdType', 'nonPlatformEarningsAmount'],
};

export const batchFileValidHeaders = {
  [BatchFileType.Worker]: [
    'metadata',
    'piiType',
    'backgroundCheckId',
    'ssn',
    'dateOfBirth',
    'firstName',
    'lastName',
    'phoneNumber',
    'email',
    'street',
    'street2',
    'city',
    'state',
    'postalCode',
    'country',
  ],
  [BatchFileType.Payout]: [
    'workerCheckrPayId',
    'workerMetadata',
    'payoutMetadata',
    'workerIdType',
    'amountCents',
    'description',
    'requestId',
  ],
  [BatchFileType.Taxes1099]: [
    'workerIdType',
    'workerCheckrPayId',
    'workerMetadata',
    'nonPlatformEarningsAmount',
  ],
};

// NOTE(Carter): This DTOs must be kept in sync with corresponding schemas on the backend!
// Those DTOs live in jobs/batch/batch_processor.js on the server
export const createWorkerBatchRecordSchema = Joi.object({
  metadata: Joi.string().csvInjection().optional(),
  piiType: Joi.string().csvInjection().required().valid('backgroundCheckId', 'manual'),

  // BGC ID (required if and only if piiType == backgroundCheckId)
  backgroundCheckId: Joi.string().csvInjection().optional(),

  // PII fields (required if and only if piiType == manual)
  ssn: Joi.string()
    .csvInjection()
    .pattern(/^[\dX]{3}-[\dX]{2}-[\dX]{4}$/i)
    .optional(),
  dateOfBirth: Joi.date().optional(),
  firstName: Joi.string().csvInjection().optional(),
  lastName: Joi.string().csvInjection().optional(),

  // Profile fields
  phoneNumber: phoneNumberSchema.required(),
  email: Joi.string()
    .csvInjection()
    .email({ tlds: { allow: false } })
    .optional(),
  street: Joi.string().csvInjection().optional(),
  street2: Joi.string().csvInjection().optional().allow(''),
  city: Joi.string().csvInjection().optional(),
  state: Joi.string().csvInjection().optional(),
  postalCode: Joi.string().csvInjection().optional().allow(''),
  country: Joi.string().csvInjection().optional().valid('US'),
});

export const payoutBatchRecordSchema = Joi.object({
  workerIdType: Joi.string().required().valid('checkrPayId', 'metadata'),
  workerCheckrPayId: Joi.string().csvInjection().when('workerIdType', {
    is: 'checkrPayId',
    then: Joi.required(),
    otherwise: Joi.forbidden(),
  }),
  workerMetadata: Joi.string().csvInjection().when('workerIdType', {
    is: 'metadata',
    then: Joi.required(),
    otherwise: Joi.forbidden(),
  }),

  amountCents: Joi.number().integer().positive().required(),
  description: Joi.string().csvInjection().max(256).optional(),

  payoutMetadata: Joi.string().csvInjection().max(256).optional(),
  requestId: Joi.string().csvInjection().optional(),
});

export const taxes1099BatchRecordSchema = Joi.object({
  workerIdType: Joi.string().required().valid('checkrPayId', 'metadata'),
  workerCheckrPayId: Joi.string().when('workerIdType', {
    is: 'checkrPayId',
    then: Joi.required(),
    otherwise: Joi.forbidden(),
  }),
  workerMetadata: Joi.string().when('workerIdType', {
    is: 'metadata',
    then: Joi.required(),
    otherwise: Joi.forbidden(),
  }),

  nonPlatformEarningsAmount: Joi.number().integer().required(),
});

export function getErrorsFromCSVHeaders(
  type: BatchFileType,
  csvHeaders: Array<string>,
  validDynamicHeaders?: Array<string>
) {
  const requiredHeaders = batchFileRequiredHeaders[type];
  const validHeaders = batchFileValidHeaders[type];

  // Check for missing headers that are required
  const missingHeaderErrors = requiredHeaders
    .map((required) => {
      if (!csvHeaders.includes(required)) {
        return `Missing CSV Header: ${required} header is required for this CSV.`;
      } else {
        return null;
      }
    })
    .filter((e) => e);

  // Check for invalid headers as well
  const invalidHeaderErrors = csvHeaders
    .map((provided) => {
      if (!validHeaders.includes(provided) && !validDynamicHeaders?.includes(provided)) {
        return `Invalid CSV Header: ${provided} is not a valid field for this CSV.`;
      } else {
        return null;
      }
    })
    .filter((e) => e);

  // Note: We've filtered out the null entries
  return missingHeaderErrors.concat(invalidHeaderErrors) as Array<string>;
}

export function getErrorsFromWorkerCSVData(csvData: Array<WorkerBatchData>) {
  const errors: Array<string> = [];
  csvData.forEach((worker, lineNumber) => {
    const { error } = createWorkerBatchRecordSchema.validate(worker, {
      abortEarly: false,
    });
    if (error && error.details && error.details.length > 0) {
      error.details.forEach(({ message }: { message: string }) => {
        errors.push(`Error on line ${1 + 1 + lineNumber}: ${message}`); // +1 to account for header
      });
    }
  });
  return errors;
}

export function getErrorsFromPayoutCSVData(
  csvData: Array<PayoutBatchData>,
  payoutGroupDefinitions: Array<PayoutGroupDefinition>
) {
  const schema = payoutBatchRecordSchema.concat(
    buildPayoutGroupDefinitionSchema(payoutGroupDefinitions)
  );

  const errors: Array<string> = [];

  csvData.forEach((payout, lineNumber) => {
    const { error } = schema.validate(payout, {
      abortEarly: false,
    });
    if (error && error.details && error.details.length > 0) {
      error.details.forEach(({ message }: { message: string }) => {
        errors.push(`Error on line ${1 + 1 + lineNumber}: ${message}`); // +1 to account for header
      });
    }
  });
  return errors;
}

const buildPayoutGroupDefinitionSchema = (payoutGroupDefinitions: Array<PayoutGroupDefinition>) => {
  const schema = payoutGroupDefinitions.reduce((all: any, definition: PayoutGroupDefinition) => {
    all[definition.name] = Joi.string()
      .optional()
      .valid(...definition.allowedValues.map((allowedValue) => allowedValue.name));

    return all;
  }, {});

  return Joi.object(schema).optional();
};

export function getErrorsFromTaxesCSVData(csvData: Array<Taxes1099BatchData>) {
  const errors: Array<string> = [];
  csvData.forEach((new1099, lineNumber) => {
    const { error } = taxes1099BatchRecordSchema.validate(new1099, {
      abortEarly: false,
    });
    if (error && error.details && error.details.length > 0) {
      error.details.forEach(({ message }: { message: string }) => {
        errors.push(`Error on line ${1 + lineNumber}: ${message}`);
      });
    }
  });
  return errors;
}
