import { ReactComponent as CheckIcon } from '@/shared/assets/check-mark.svg';
import { ReactComponent as CopyIcon } from '@/shared/assets/copy-icon.svg';
import { CardTitle, ContentCard } from '@/shared/components/Card';
import { colors } from '@/shared/styles';
import { Button, Modal } from '@checkrx/pay-component-library';
import styled from 'styled-components';

import {
  useBankingAccountNumber,
  useBankingAccountRoutingNumber,
} from '@/services/useBankingAccount';
import { useCustomerProfilePermissions } from '@/services/useCustomerProfile';
import useLoading from '@/shared/hooks/useLoading';
import { LoadingScope, TabPermissions } from '@/shared/types';
import { useEffect, useState } from 'react';
import DepositFundsModal from './DepositFundsModal';

const DepositCardWrapper = styled(ContentCard)`
  flex: 2;
  height: 100%;
  min-height: fit-content;
  gap: 15px;
`;

const Text = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${colors.labelGrey};
  line-height: 18px;
`;

const NumberRow = styled.div`
  display: flex;
  margin-top: 5px;
  gap: 20px;
  align-items: center;
  justify-content: start;
  width: 100%;
  min-width: 100%;
`;

const NumberBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  border: 1px ${colors.dividerGrey} solid;
  border-radius: 10px;
  padding: 15px;
  height: fit-content;
`;

const NumberTextCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: start;
  align-items: start;
`;

const NumberLabelText = styled(Text)`
  font-weight: 600;
  color: ${colors.primaryBlue};
`;

const CopyButton = styled.button`
  &:hover {
    cursor: pointer;
  }
`;

export default function DepositCard() {
  const { data: accountNumber, isLoading: accountLoading } = useBankingAccountNumber();
  const { data: routingNumber, isLoading: routingLoading } = useBankingAccountRoutingNumber();
  const [hidden, setHidden] = useState(true);
  const [copiedAccountNumber, setCopiedAccountNumber] = useState(false);
  const [copiedRoutingNumber, setCopiedRoutingNumber] = useState(false);
  const [isDepositFundsModalOpen, setIsDepositFundsModalOpen] = useState(false);
  const setLoading = useLoading(accountLoading || routingLoading, LoadingScope.customerDashboard);
  const { data: permissions } = useCustomerProfilePermissions();
  const canViewSensitiveData =
    permissions &&
    (permissions.sensitiveAccountData === TabPermissions.read ||
      permissions.sensitiveAccountData === TabPermissions.write);
  const canWriteSensitiveData = permissions?.sensitiveAccountData === TabPermissions.write;

  useEffect(() => {
    setLoading(accountLoading || routingLoading);
  }, [accountLoading, routingLoading, setLoading]);

  const copyAccountNumberToClipboard = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCopiedAccountNumber(true);
    setCopiedRoutingNumber(false);
    navigator.clipboard.writeText(accountNumber ?? '');
  };
  const copyRoutingNumberToClipboard = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCopiedRoutingNumber(true);
    setCopiedAccountNumber(false);
    navigator.clipboard.writeText(routingNumber ?? '');
  };

  return (
    <DepositCardWrapper>
      <Modal
        isOpen={isDepositFundsModalOpen}
        close={() => {
          setIsDepositFundsModalOpen(false);
        }}
        headerText="Deposit Funds"
        modalContent={<DepositFundsModal />}
      />
      <CardTitle>Deposit</CardTitle>
      <Text>In order to deposit money, you need to transfer money to below account:</Text>
      <NumberRow>
        <NumberBox className="fullview-hide">
          <NumberTextCol>
            <NumberLabelText>Routing number</NumberLabelText>
            <Text>{hidden ? '• • • • • • • • •' : routingNumber}</Text>
          </NumberTextCol>
          {!hidden &&
            (copiedRoutingNumber ? (
              <CheckIcon />
            ) : (
              <CopyButton onClick={copyRoutingNumberToClipboard}>
                <CopyIcon />
              </CopyButton>
            ))}
        </NumberBox>
        <NumberBox className="fullview-hide">
          <NumberTextCol>
            <NumberLabelText>Account number</NumberLabelText>
            <Text>
              {hidden ? `• • • •  • • • •  ${(accountNumber || '').slice(-4)}` : accountNumber}
            </Text>
          </NumberTextCol>
          {!hidden &&
            (copiedAccountNumber ? (
              <CheckIcon />
            ) : (
              <CopyButton onClick={copyAccountNumberToClipboard}>
                <CopyIcon />
              </CopyButton>
            ))}
        </NumberBox>
        <Button
          width="170px"
          sizeVariant="big"
          colorVariant="brand"
          text={hidden ? 'Show details' : 'Hide details'}
          disabled={!canViewSensitiveData}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setHidden(!hidden);
          }}
        />
        {canWriteSensitiveData && (
          <Button
            width="170px"
            sizeVariant="big"
            colorVariant="brand"
            text="Deposit Funds"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              setIsDepositFundsModalOpen(true);
            }}
          />
        )}
      </NumberRow>
    </DepositCardWrapper>
  );
}
