import { useDocumentEvent } from '@/shared/hooks/useDocumentEvent';
import { colors } from '@/shared/styles';
import { Button } from '@checkrx/pay-component-library';
import React, { ComponentProps, useRef, useState } from 'react';
import styled from 'styled-components';
import Tether from './Tether';

interface MenuOption {
  label: string;
  action: () => void;
  state?: 'enabled' | 'disabled' | 'hidden';
}

type MenuButtonProps = {
  options: MenuOption[];
  alignDropdown?: 'left' | 'right';
} & ComponentProps<typeof Button>;

const MenuButtonContainer = styled.div`
  position: relative;
`;

const DropdownMenu = styled.div<{ alignDropdown?: 'left' | 'right'; buttonWidth?: number }>`
  position: absolute;
  background-color: ${colors.trueWhite};
  border: 1px solid ${colors.dividerGrey};
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 4px 0;
  min-width: 144px;
  ${({ alignDropdown, buttonWidth }) =>
    alignDropdown === 'right' ? `right: ${0 - (buttonWidth ?? 0)}px;` : `left: 0;`}
`;

const MenuOption = styled.div<{ disabled?: boolean }>`
  padding: 4px 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled }) => (disabled ? colors.labelGrey : 'inherit')};
  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'inherit' : colors.backgroundGrey)};
  }
`;

const MenuButton: React.FC<MenuButtonProps> = ({ options, alignDropdown = 'right', ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonContainerRef = useRef<HTMLDivElement>(null);

  useDocumentEvent('click', (e) => {
    if (
      isOpen &&
      toggleRef.current &&
      !toggleRef.current.contains(e.target as Node) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  });

  const dropdownContent = (
    <DropdownMenu
      ref={dropdownRef}
      alignDropdown={alignDropdown}
      buttonWidth={buttonContainerRef.current?.offsetWidth}
    >
      {options.map((option, index) => {
        if (option.state === 'hidden') return null;
        return (
          <MenuOption
            key={index}
            onClick={() => {
              if (option.state === 'disabled') return;
              setIsOpen(false);
              option.action();
            }}
            disabled={option.state === 'disabled'}
          >
            {option.label}
          </MenuOption>
        );
      })}
    </DropdownMenu>
  );

  return (
    <MenuButtonContainer ref={toggleRef}>
      <div ref={buttonContainerRef} style={{ width: 'min-content' }}>
        <Button {...rest} onClick={() => setIsOpen(!isOpen)} />
      </div>
      <Tether target={buttonContainerRef} align="bottom" offset={{ vertical: 8 }} show={isOpen}>
        {dropdownContent}
      </Tether>
    </MenuButtonContainer>
  );
};

export default MenuButton;
