/**
 * Content of the modal that opens when you request to make a payout
 */
import { useCreateOnePayout } from '@/services/usePayouts';
import { useOneWorker } from '@/services/useWorkers';
import { ReactComponent as ErrorGraphic } from '@/shared/assets/error-graphic.svg';
import { ReactComponent as SuccessGraphic } from '@/shared/assets/success-graphic.svg';
import { Legalese } from '@/shared/components/BatchUpload.styled';
import { ResultsRow } from '@/shared/components/CreationComponents';
import DollarAmount from '@/shared/components/DollarAmount';
import { Payout } from '@/shared/types';
import { Button, Check, Spinner } from '@checkrx/pay-component-library';
import { AxiosError, isAxiosError } from 'axios';
import { useState } from 'react';

import { useBankingAccountBalance } from '@/services/useBankingAccount';
import {
  ButtonRow,
  CenteredErrorText,
  CenteredSuccessText,
  CheckRow,
  ContentWrapper,
  DescriptionText,
  ImgWrapper,
  LabelRow,
  LabelText,
  ValueText,
} from '@/shared/components/ConfirmationModals.styled';
import { colors } from '@/shared/styles';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import styled from 'styled-components';

const successToMessage = (payout: Payout) => {
  return (
    <CenteredSuccessText>
      Successfully created new pending payout with ID {payout.id}
    </CenteredSuccessText>
  );
};
const SupportLink = styled.a`
  color: ${colors.accentTeal};
  font-size: 600;
  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: ${colors.accentTeal};
  }
`;
const errorToMessage = (err: Error) => {
  if (isAxiosError(err)) {
    const status = err?.response?.status;
    if (status === 404) {
      return (
        <>
          <CenteredErrorText key="error">
            We couldn&apos;t find a worker with the specified worker ID. Feel free to close this
            modal and try again. You can also visit our{' '}
            <SupportLink
              href="https://customersupport.checkrpay.com/hc/en-us"
              target="_blank"
              rel="noreferrer"
            >
              Customer Support Center
            </SupportLink>{' '}
            or contact{' '}
            <SupportLink href="mailto:customersupport@checkrpay.com" rel="noreferrer">
              CustomerSupport@checkrpay.com
            </SupportLink>{' '}
            for more help.
          </CenteredErrorText>
        </>
      );
    }
    const {
      data: { error_message: errorMessage, details: errorDetails },
    } = err?.response || { data: {} };

    let details = errorDetails;
    if (details?.error) {
      details = details?.error?.details || [];
    }

    return (
      <>
        <CenteredErrorText key="error">{`${errorMessage}`}</CenteredErrorText>
        {(details || []).map(({ message, type }: { message: string; type: string }) => (
          <CenteredErrorText key={type}>{message}</CenteredErrorText>
        ))}
      </>
    );
  } else {
    return <CenteredErrorText>{`${err.message}`}</CenteredErrorText>;
  }
};

interface PayoutConfirmationModalProps {
  workerId: string;
  paymentAmount: number;
  // Function to Clear out the Parents' state on success
  clearParentFn: () => void;
  metadata?: string;
  description?: string;
}
export default function PayoutConfirmationModal({
  workerId,
  paymentAmount,
  clearParentFn,
  metadata,
  description,
}: PayoutConfirmationModalProps) {
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const {
    isLoading: createOneIsLoading,
    isError: createOneIsError,
    isSuccess: createOneIsSuccess,
    error: createOneError,
    data: createOneData,
    mutate: createSinglePayout,
  } = useCreateOnePayout();

  // Fetch this worker from the DB
  const {
    data: workerProfile,
    isLoading: isLoadingWorker,
    isError: isWorkerError,
    error: workerError,
  } = useOneWorker(workerId);

  const { data: balance, isLoading: isBalanceLoading } = useBankingAccountBalance();

  const onSubmitOnePayment = async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // Create the payout
    createSinglePayout({
      workerId,
      amount: paymentAmount,
      metadata: metadata ? metadata : undefined,
      description: description ? description : undefined,
    });

    // Sleep for a second before clearing the parent's state to avoid weird UX
    await new Promise((r) => setTimeout(r, 1000));

    // Clear the parents' state
    clearParentFn();
  };

  // Initial load should return just a spinner
  if (isLoadingWorker || isBalanceLoading) {
    return (
      <ImgWrapper>
        <Spinner />
      </ImgWrapper>
    );
  }

  // Errors should show a polite message with our error graphic
  if (isWorkerError || createOneIsError) {
    return (
      // We know one of these is non-null if we hit the above conditional
      <ImgWrapper>
        <ErrorGraphic />
        <ResultsRow>
          {errorToMessage(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ((workerError as AxiosError) || (createOneError as AxiosError))!
          )}
        </ResultsRow>
      </ImgWrapper>
    );
  }

  // Success should show a hooray graphic with a message about the completion
  if (createOneIsSuccess) {
    return (
      <ImgWrapper>
        <SuccessGraphic />
        <ResultsRow>{successToMessage(createOneData)}</ResultsRow>{' '}
      </ImgWrapper>
    );
  }

  return (
    // Otherwise we're showing the data and asking for confirmation
    <ContentWrapper>
      <DescriptionText>
        Please double check the payout and worker information below, then confirm that you wish to
        proceed. If something looks incorrect, you can close this modal to make edits. After you
        confirm and submit, all changes are final.
      </DescriptionText>
      <LabelRow>
        <LabelText>Current Account Balance:</LabelText>
        <ValueText>{formatCentsAsDollars(balance ?? 0)}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Worker Id:</LabelText>
        <ValueText>{workerId}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Worker Name:</LabelText>
        <ValueText>{workerProfile?.profile?.legalName}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Worker Email:</LabelText>
        <ValueText>{workerProfile?.profile?.email}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Payout Amount:</LabelText>
        <DollarAmount amountCents={paymentAmount} />
      </LabelRow>
      <LabelRow>
        <LabelText>Payout Metadata:</LabelText>
        <ValueText>{metadata || ''}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Payout Description:</LabelText>
        <ValueText>{description || ''}</ValueText>
      </LabelRow>
      <CheckRow>
        <Check
          checked={hasConfirmed}
          onClick={() => {
            setHasConfirmed(!hasConfirmed);
          }}
        />
        <Legalese>
          I&apos;ve confirmed the information above and authorize Checkr Pay to process this payout.
        </Legalese>
      </CheckRow>
      <ButtonRow>
        <Button
          text="Submit"
          sizeVariant="big"
          colorVariant="brand"
          disabled={!hasConfirmed || createOneIsLoading}
          width="155px"
          onClick={onSubmitOnePayment}
        />
        {createOneIsLoading ? (
          <Spinner size="30px" />
        ) : (
          // Keep this here for positioning!
          <></>
        )}
      </ButtonRow>
    </ContentWrapper>
  );
}
