import { DateString } from './types';

export enum TransactionType {
  Ach = 'Ach',
  Wire = 'Wire',
  BookTransfer = 'BookTransfer',
  Card = 'Card',
  Atm = 'Atm',
  Check = 'Check',
  Dispute = 'Dispute',
  Interest = 'Interest',
  Fee = 'Fee',
  Other = 'Other',
}

export enum TransactionDirection {
  Credit = 'Credit',
  Debit = 'Debit',
}

export enum TransactionStatus {
  Pending = 'Pending',
  PendingReview = 'PendingReview',
  Clearing = 'Clearing',
  Returned = 'Returned',
  Sent = 'Sent',
  Rejected = 'Rejected',
  Canceled = 'Canceled',
  Authorized = 'Authorized',
}

export type Transaction = {
  accountId: string;
  amount: number;
  createdAt: DateString;
  direction: string;
  metadata?: {
    customer?: string;
    customerFee?: string;
    isPayout?: string;
    payoutId?: string;
    payoutMethod?: string;
    profile?: string;
    queuedPayoutId?: string;
    queuedPayoutIdInternal?: string;
    worker?: string;
  };
  startedAt: DateString;
  summary: string;
  description?: string;
  transactionId: string;
  transactionStatus: TransactionStatus;
  transactionType: TransactionType;
  userId: string;
};
