import { colors } from '@/shared/styles';
import Icon, { IconColor, IconName } from '@checkrx/pay-component-library/dist/Icon/Icon';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export type SidebarItemT = {
  text: string;
  icon: IconName;
  iconColor?: IconColor;
  disabled?: boolean;
  showNewBadge?: boolean;
  to?: string;
  onClick?: () => void;
  active?: boolean;
};

const ItemWrapper = styled.div<{ active?: boolean; disabled: boolean; isLink: boolean }>`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  background: ${(p) => (p.active ? colors.accentTeal : 'transparent')};
  border-radius: 6px;
  padding-left: 12px;
  padding-right: 12px;
  transition: filter 0.2s ease-in-out;

  &:hover {
    filter: ${(p) => (p.active ? 'brightness(1)' : 'brightness(0.7)')};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledText = styled.h2<{ active?: boolean; disabled: boolean }>`
  font-size: 1rem;
  color: ${(p) => {
    if (p.disabled) return colors.dividerGrey;
    return p?.active ? colors.trueWhite : colors.labelGrey;
  }};
  font-weight: ${(p) => (p?.active ? 600 : 400)};
  line-height: 20px;
`;

const SidebarItem: FC<SidebarItemT> = ({
  text,
  to,
  icon,
  iconColor,
  disabled = false,
  active = false,
  onClick,
}) => {
  const Content = (
    <>
      <ContentWrapper>
        <Icon name={icon} color={disabled ? 'disabled' : active ? 'white' : iconColor} />
        <StyledText active={active} disabled={disabled}>
          {text}
        </StyledText>
      </ContentWrapper>
    </>
  );

  if (to) {
    return (
      <ItemWrapper as={Link} to={to} active={active} disabled={disabled} isLink={true}>
        {Content}
      </ItemWrapper>
    );
  }

  return (
    <ItemWrapper
      onClick={disabled ? undefined : onClick}
      active={active}
      disabled={disabled}
      isLink={false}
    >
      {Content}
    </ItemWrapper>
  );
};

export default SidebarItem;
