import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useRequestMagicLink } from '@/services/login';
import Card from '@/shared/components/Card';
import { Button, Check, TextInput } from '@checkrx/pay-component-library';

import { LoadingGuard } from '@/app/wrappers/LoadingGuard';
import { useAuth } from '@/services/useAuth';
import { ReactComponent as PayLogo } from '@/shared/assets/logo.svg';
import { CheckRow } from '@/shared/components/ConfirmationModals.styled';
import useLoading from '@/shared/hooks/useLoading';
import { colors } from '@/shared/styles';
import { LoadingScope } from '@/shared/types';
import { Navigate } from 'react-router-dom';
import StandaloneSidebar from '../../shared/components/StandaloneSidebar';

const LoginCard = styled(Card)`
  margin-left: auto;
  margin-right: auto;
  gap: 16px;
`;

const LoginText = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 12px;
  line-height: 28px;
  font-weight: 600;
  color: ${colors.primaryBlue};
`;

const RememberEmailText = styled.span`
  font-size: 14px;
  color: ${colors.textGrey};
`;

const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

const ErrorText = styled.p`
  font-size: 1rem;
  line-height: 18px;
  font-weight: 400;
  color: ${colors.primaryRed};
`;

const CHECKR_PAY_EMAIL_STORAGE_KEY = 'CHECKR-PAY_customer-email';
const CHECKR_PAY_REMEMBER_EMAIL_STORAGE_KEY = 'CHECKR-PAY_remember-email';

const LoginPage = () => {
  const storageRememberEmail =
    window?.localStorage?.getItem?.(CHECKR_PAY_REMEMBER_EMAIL_STORAGE_KEY) === 'true' ?? false;
  const storageEmail = window?.localStorage?.getItem?.(CHECKR_PAY_EMAIL_STORAGE_KEY) ?? '';
  const [rememberEmail, setRememberEmail] = useState(storageRememberEmail);
  const [email, setEmail] = useState(storageEmail);
  const { token } = useAuth();
  // Catch 400s and 404s explicitly to handle.
  const { isLoading, isSuccess, isError, mutate: requestMagicLink } = useRequestMagicLink();
  const setLoading = useLoading(isLoading, LoadingScope.login);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  if (token && token !== '') {
    return <Navigate to="/customer" />;
  }
  const onSubmit = async (e: React.MouseEvent | React.KeyboardEvent) => {
    if (rememberEmail) {
      window.localStorage.setItem(CHECKR_PAY_EMAIL_STORAGE_KEY, email);
    } else {
      window.localStorage.setItem(CHECKR_PAY_EMAIL_STORAGE_KEY, '');
    }
    window.localStorage.setItem(CHECKR_PAY_REMEMBER_EMAIL_STORAGE_KEY, `${rememberEmail}`);
    e.preventDefault();
    e.stopPropagation();
    requestMagicLink(email);
  };

  // Listen for "Enter" key throughout the form
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      onSubmit(event);
    }
  };

  if (isSuccess) {
    return (
      <>
        <StandaloneSidebar />
        <LoginCard onKeyDown={onKeyDown}>
          <LoginText>Admin Dashboard</LoginText>
          <LoginCard>
            <p>Success! We sent your login link to your email.</p>
          </LoginCard>
        </LoginCard>
      </>
    );
  }
  return (
    <>
      {/* Don't Blur the sidebar on loading */}
      <StandaloneSidebar />
      <LoadingGuard loadingScope={LoadingScope.login}>
        <LoginCard onKeyDown={onKeyDown}>
          <PayLogo />
          <LoginText>Customer Portal</LoginText>
          <TextGroup>
            <TextInput
              value={email}
              width="400px"
              placeholder="Email Address"
              invalid={isError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value);
              }}
            />
            <CheckRow>
              <Check checked={rememberEmail} onClick={() => setRememberEmail((curr) => !curr)} />
              <RememberEmailText>Remember email</RememberEmailText>
            </CheckRow>
          </TextGroup>
          <Button
            width="400px"
            text="Login"
            colorVariant="brand"
            sizeVariant="big"
            onClick={onSubmit}
          />
          {isError && <ErrorText>Invalid or incorrect admin email.</ErrorText>}
        </LoginCard>
      </LoadingGuard>
    </>
  );
};

export default LoginPage;
