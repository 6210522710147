/**
 * Tooling around the customer's financing
 */

import styled from 'styled-components';

import LedgerTable from './LedgerTable';
import StatementsTable from './StatementsTable';

import { PageLayout } from '@/shared/components/PageLayout/PageLayout';
import { useGetFeatureToggle } from '@/shared/feature-toggles/feature-toggles';
import BalanceCard from './BalanceCard';
import DepositCard from './DepositCard';
import FloatedBalanceCard from './FloatedBalanceCard';

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  height: fit-content;
  min-height: 200px;
  width: 100%;
  min-width: 100%;
`;

export default function CustomerOverviewTab() {
  const showNewUi = useGetFeatureToggle('showNewUi');
  if (showNewUi)
    return (
      <PageLayout title="Finance">
        <TopRow>
          <BalanceCard />
          <FloatedBalanceCard />
          <DepositCard />
        </TopRow>
        <LedgerTable />
        <StatementsTable />
        {/* Extra div at the end for bottom spacing */}
        <div />
      </PageLayout>
    );

  return (
    <>
      <TopRow>
        <BalanceCard />
        <FloatedBalanceCard />
        <DepositCard />
      </TopRow>
      <LedgerTable />
      <StatementsTable />
      {/* Extra div at the end for bottom spacing */}
      <div />
    </>
  );
}
