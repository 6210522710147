/**
 * Content of the modal that opens when you request to make a payout
 */
import DollarAmount from '@/shared/components/DollarAmount';
import { Button, Spinner, TextInput } from '@checkrx/pay-component-library';

import { toast } from '@/app/wrappers/Toaster/Toaster';
import { useCreateOneTaxes, useRequestCorrection } from '@/services/useWorkerTaxes';
import {
  ButtonRow,
  ContentWrapper,
  DescriptionText,
  LabelRow,
  LabelText,
  ValueText,
} from '@/shared/components/ConfirmationModals.styled';
import { useState } from 'react';
import { PreviousWorkModal, TaxYears, WorkerWithEarnings } from './taxes.types';

type PreviousIncomeModalProps = {
  type: PreviousWorkModal;
  worker: WorkerWithEarnings;
  taxYear: TaxYears;
  onClose: () => void;
};

const TOTAL_REQUIRED_FOR_TAX_LIABILITY = 600_00;

const PreviousIncomeModal = ({ type, worker, taxYear, onClose }: PreviousIncomeModalProps) => {
  const [externalEarnings, setExternalEarnings] = useState<number>(0);
  const { mutateAsync: createOneTax, isLoading: createLoading } = useCreateOneTaxes();
  const { mutateAsync: requestCorrection, isLoading: correctionLoading } = useRequestCorrection();

  const handleCreateTaxAccount = async () => {
    const earnerTotalEarnings = worker.earnedCentsTotal + externalEarnings;
    if (earnerTotalEarnings < TOTAL_REQUIRED_FOR_TAX_LIABILITY) {
      toast({
        message: '1099-NEC not required for less than $600 total earnings',
        type: 'error',
        duration: 5000,
      });
      return;
    }
    try {
      await createOneTax({
        workerProfileId: worker.id,
        year: taxYear,
        nonPlatformEarningsAmount: Number(externalEarnings),
      });
      toast({
        message: `Created tax account for ${worker.profile?.legalName}`,
        type: 'success',
        duration: 5000,
      });
      onClose();
    } catch (err) {
      toast({
        message: `Failed to create tax account for ${worker.profile?.legalName}`,
        type: 'error',
        duration: 5000,
      });
    }
  };

  const handleRequestCorrection = async () => {
    try {
      await requestCorrection({
        workerProfileId: worker.id,
        year: taxYear,
        nonPlatformEarningsAmount: Number(externalEarnings),
      });
      toast({
        message: `Requested correction for ${worker.profile?.legalName}`,
        type: 'success',
        duration: 5000,
      });
      onClose();
    } catch (err) {
      toast({
        message: `Failed to request correction for ${worker.profile?.legalName}`,
        type: 'error',
        duration: 5000,
      });
    }
  };

  const handleSubmit = () => {
    if (type === 'correction') {
      handleRequestCorrection();
    }
    if (type === 'create') {
      handleCreateTaxAccount();
    }
  };

  const buttonText = () => {
    if (type === 'correction') {
      return externalEarnings === 0 ? 'Cancel' : 'Submit';
    }
    return externalEarnings === 0 ? 'Skip' : 'Submit';
  };

  return (
    <ContentWrapper>
      <DescriptionText>
        Enter the amount the earner has made outside of Checkr Pay for tax year ({taxYear})
      </DescriptionText>
      <LabelRow>
        <LabelText>Earner:</LabelText>
        <ValueText>{worker.profile?.legalName}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Earnings in Checkr Pay:</LabelText>
        <DollarAmount amountCents={worker.earnedCentsTotal} />
      </LabelRow>
      {type === 'correction' && !!worker?.['1099nec'] && (
        <LabelRow>
          <LabelText>External earnings:</LabelText>
          <DollarAmount amountCents={Number(worker?.['1099nec']?.[taxYear]?.nonPlatformIncome)} />
        </LabelRow>
      )}
      {type === 'correction' && !!worker?.['1099nec'] && (
        <LabelRow>
          <LabelText>Total earnings:</LabelText>
          <DollarAmount amountCents={Number(worker?.['1099nec']?.[taxYear]?.totalIncome)} />
        </LabelRow>
      )}
      <LabelText>{type === 'correction' ? 'Override Earnings' : 'External earnings'}</LabelText>
      <TextInput
        width="280px"
        placeholder={type === 'correction' ? 'Override Earnings' : 'External earnings'}
        height="60px"
        value={
          externalEarnings
            ? Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format((externalEarnings || 0) / 100)
            : undefined
        }
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, '');
          if (value === '') return;
          // Parse from Intl number format to integer value
          if (value.includes('$')) {
            setExternalEarnings(parseInt(value.slice(1).replaceAll(',', '').split('.').join('')));
          } else {
            setExternalEarnings(parseInt(value.replaceAll(',', '')));
          }
        }}
      />

      <ButtonRow>
        <Button
          text={buttonText()}
          sizeVariant="big"
          colorVariant="brand"
          width="155px"
          onClick={handleSubmit}
        />
        {(createLoading || correctionLoading) && <Spinner size="30px" />}
      </ButtonRow>
    </ContentWrapper>
  );
};

export default PreviousIncomeModal;
