import useFeatureToggles, {
  DEFAULT_FEATURE_TOGGLES,
  ToggleKey,
  TOGGLE_KEYS,
} from './feature-toggles';

import { Toggle } from '@checkrx/pay-component-library';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { colors } from '../styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 25%;
  left: calc(50% - 200px);
  width: 400px;
  min-height: 400px;
  background: ${colors.primaryWhite};
  border: 1px solid ${colors.dividerGrey};
  border-radius: 12px;
  padding: 12px;
  box-shadow:
    6px 12px 24px -2px rgba(50, 50, 75, 0.2),
    5px 10px 20px -3px rgba(100, 100, 100, 0.3);
  z-index: 1000;
`;

const Title = styled.div`
  width: 100%;
  font-size: 22px;
  color: ${colors.primaryBlue};
  font-weight: bold;
  margin-bottom: 16px;
`;

const ToggleList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  scrollbar-width: thin; /* scroll-s */
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ToggleItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ToggleKeyText = styled.span`
  color: ${colors.labelGrey};
`;

const ToggleMiniText = styled.span`
  color: ${colors.labelGrey};
  font-size: 12px;
`;

const ToggleRemoveText = styled.span`
  color: ${colors.accentTeal};
  &:hover {
    filter: brightness(80%);
  }
  cursor: pointer;
`;

export const FeatureToggles = () => {
  const [show, setShow] = useState(false);
  const { listActiveFeatureToggles, setFeatureToggle, removeFeatureToggle } = useFeatureToggles();

  useEffect(() => {
    const handleKeysShow = ({
      metaKey,
      shiftKey,
      key,
    }: {
      metaKey: boolean;
      shiftKey: boolean;
      key: string;
    }) => {
      if (metaKey && shiftKey && key === 'l') {
        setShow((prev) => !prev);
      }
      if (key === 'Escape') {
        setShow(false);
      }
    };
    document.addEventListener('keydown', handleKeysShow);
    return () => document.removeEventListener('keydown', handleKeysShow);
  }, []);

  if (!show) return null;

  const toggles = listActiveFeatureToggles();
  const handleToggleChange = (key: ToggleKey, value: boolean) => setFeatureToggle({ key, value });

  const notUsedInCode = (key: ToggleKey) => !TOGGLE_KEYS.includes(key as ToggleKey);

  return createPortal(
    <Container>
      <Title>Toggles</Title>
      <ToggleList>
        {Object.entries(toggles).map(([key, value]) => {
          const toggleKey = key as ToggleKey;
          return (
            <ToggleItem key={toggleKey}>
              <ToggleKeyText>
                {toggleKey}{' '}
                {notUsedInCode(toggleKey) && (
                  <ToggleMiniText className="text-secondary">
                    (not used in code){' '}
                    <ToggleRemoveText onClick={() => removeFeatureToggle({ key: toggleKey })}>
                      remove
                    </ToggleRemoveText>
                  </ToggleMiniText>
                )}
              </ToggleKeyText>
              <ToggleContainer>
                <ToggleMiniText>
                  Default: {DEFAULT_FEATURE_TOGGLES[toggleKey] ? 'On' : 'Off'}
                </ToggleMiniText>
                <Toggle
                  isOn={value}
                  onClick={() => handleToggleChange(toggleKey, !value)}
                  disabled={notUsedInCode(toggleKey)}
                />
              </ToggleContainer>
            </ToggleItem>
          );
        })}
      </ToggleList>
    </Container>,
    document.body
  );
};
