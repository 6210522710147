import { ReactComponent as BalanceIcon } from '@/shared/assets/balance-icon.svg';
import Card from '@/shared/components/Card';
import { colors } from '@/shared/styles';
import styled from 'styled-components';

import { useCustomer } from '@/services/useCustomerProfile';
import DollarAmount from '@/shared/components/DollarAmount';
import useLoading from '@/shared/hooks/useLoading';
import { LoadingScope } from '@/shared/types';
import { useEffect } from 'react';

// Same for bottom row but the bottom row is larger
const BalanceCardWrapper = styled(Card)`
  flex: 1;
  flex-direction: row;
  height: 100%;
  min-height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const TextRow = styled.div`
  display: flex;
  flex: row;
  width: fit-content;
  align-items: end;
  gap: 5px;
`;

const IconCircle = styled.div`
  height: 60px;
  min-height: 60px;
  width: 60px;
  min-width: 60px;
  background-color: ${colors.accentGreen};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const BalanceColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: fit-content;
  min-height: fit-content;
  width: fit-content;
  min-width: fit-content;
`;

const BalanceTitleText = styled.h3`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.labelGrey};
  line-height: 20px;
`;

// const BalanceText = styled.p`
//   font-size: 1.5rem;
//   font-weight: 600;
//   color: ${colors.primaryBlue};
//   line-height: 30px;
// `;

// const BigBalance = styled.span`
//   font-size: 2.5rem;
//   line-height: 50px;
// `;

export default function FloatedBalanceCard() {
  const { data: customer, isLoading } = useCustomer();
  const setLoading = useLoading(isLoading, LoadingScope.customerDashboard);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  return (customer?.maximumFloat || 0) > 0 ? (
    <BalanceCardWrapper>
      <IconCircle>
        <BalanceIcon />
      </IconCircle>
      <BalanceColumn>
        <BalanceTitleText>Floated Balance</BalanceTitleText>
        {!isLoading && (
          <TextRow>
            <DollarAmount
              amountCents={customer?.floatedBalance || 0}
              preambleSize="1rem"
              dollarSize="2rem"
              centSize="1rem"
              color={colors.accentOrange}
            />
            <p> / </p>
            <DollarAmount
              amountCents={customer?.maximumFloat || 0}
              color={colors.primaryBlue}
              preambleSize=".875rem"
              dollarSize="1rem"
              centSize=".875rem"
            />
          </TextRow>
        )}
      </BalanceColumn>
    </BalanceCardWrapper>
  ) : (
    <></>
  );
}
