/**
 * The Customer Payout tab for sending a querying payouts
 */

import { useCustomerProfile, useCustomerProfilePermissions } from '@/services/useCustomerProfile';
import { PageLayout } from '@/shared/components/PageLayout/PageLayout';
import { useGetFeatureToggle } from '@/shared/feature-toggles/feature-toggles';
import { colors } from '@/shared/styles';
import { TabPermissions } from '@/shared/types';
import { Spinner } from '@checkrx/pay-component-library';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import PayoutsBatchFileTable from './PayoutsBatchFileTable';
import PayoutsTable from './PayoutsTable';
import SendPayoutsCard from './SendPayoutsCard';

const LoadingPayouts = styled.div`
  width: 368px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: ${colors.dividerGrey};
  border-radius: 10px;
  padding-left: 40px;
  cursor: not-allowed;
  font-size: 14px;
  color: ${colors.textGrey};
`;

export default function CustomerPayoutsTab() {
  const { data: permissions } = useCustomerProfilePermissions();
  const { data: customerProfile, isLoading } = useCustomerProfile();
  const hasWritePermissions = permissions?.payoutsTab === TabPermissions.write;

  const [searchParams] = useSearchParams();
  const payWorkerId = searchParams.get('payWorkerId');
  const showNewUi = useGetFeatureToggle('showNewUi');

  if (isLoading || !customerProfile)
    return (
      <LoadingPayouts>
        <div>Loading payouts</div> <Spinner size="24px" />
      </LoadingPayouts>
    );

  if (showNewUi) {
    return (
      <PageLayout title="Payouts">
        {hasWritePermissions && <SendPayoutsCard />}
        {hasWritePermissions && <PayoutsBatchFileTable />}
        <PayoutsTable payWorkerId={payWorkerId || undefined} customerProfile={customerProfile} />
        {/* Extra div at the end for bottom spacing */}
        <div />
      </PageLayout>
    );
  }
  return (
    <>
      {hasWritePermissions && <SendPayoutsCard />}
      {hasWritePermissions && <PayoutsBatchFileTable />}
      <PayoutsTable payWorkerId={payWorkerId || undefined} customerProfile={customerProfile} />
      {/* Extra div at the end for bottom spacing */}
      <div />
    </>
  );
}
