import { pluralize } from '@/shared/utils/pluralize';
import { Button, Modal } from '@checkrx/pay-component-library';
import { FC, useState } from 'react';
import MultiFileModal from './MultiFileModal';
import { TaxYears, WorkerWithEarnings } from './taxes.types';

type Props = {
  selectedIds: WorkerWithEarnings['id'][];
  workers: WorkerWithEarnings[];
  taxYear: TaxYears;
};

const NO_ACTION = 'NO_ACTION';
const CREATED = 'CREATED';

const MULTI_FILE_ACTIONS = [NO_ACTION, CREATED] as const;
type MultiFileAction = (typeof MULTI_FILE_ACTIONS)[number];

export const MultiFileButton: FC<Props> = ({ selectedIds, workers, taxYear }) => {
  const [showMultiFileModal, setShowMultiFileModal] = useState(false);
  const workersThatCanFile = selectedIds?.reduce((acc, curr) => {
    const worker = workers?.find((fw) => fw.id === curr);
    const workerStatus = worker?.['1099nec']?.[taxYear]?.status;
    if (workerStatus === CREATED) {
      acc.push(worker?.id ?? '');
    }
    return acc;
  }, [] as string[]);

  const getMultiSelectAction = () => {
    if (workersThatCanFile.length > 0) return CREATED;
    return NO_ACTION;
  };

  const taxActionSelectedLength = workersThatCanFile.length;

  const taxActionTextMap: Record<MultiFileAction, string> = {
    CREATED: 'File',
    NO_ACTION: 'No action',
  };

  return (
    <>
      <Button
        text={
          taxActionSelectedLength > 0
            ? `${
                taxActionTextMap[getMultiSelectAction()]
              } for ${taxActionSelectedLength} ${pluralize('earner', taxActionSelectedLength)}`
            : 'Select earners ready to file'
        }
        sizeVariant="big"
        onClick={() => setShowMultiFileModal(true)}
        width="224px"
        colorVariant="accent"
        disabled={taxActionSelectedLength === 0 || getMultiSelectAction() === NO_ACTION}
      />
      <Modal
        isOpen={showMultiFileModal}
        close={() => {
          setShowMultiFileModal(false);
        }}
        headerText="Confirm your filing"
        subHeaderText=""
        modalContent={
          <MultiFileModal
            workerIds={workersThatCanFile}
            taxYear={taxYear}
            onClose={() => setShowMultiFileModal(false)}
          />
        }
      />
    </>
  );
};
