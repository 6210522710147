/**
 * Content of the modal that opens when you request a deposit
 */
import { useCreateDeposit } from '@/services/useDeposits';
import { ReactComponent as ErrorGraphic } from '@/shared/assets/error-graphic.svg';
import { ReactComponent as SuccessGraphic } from '@/shared/assets/success-graphic.svg';
import { ButtonRow, Legalese } from '@/shared/components/BatchUpload.styled';
import {
  CenteredErrorText,
  CenteredSuccessText,
  CheckRow,
  ContentWrapper,
  DescriptionText,
  ImgWrapper,
  LabelText,
} from '@/shared/components/ConfirmationModals.styled';
import { LabelRow, ResultsRow } from '@/shared/components/CreationComponents';
import { colors } from '@/shared/styles';
import { isAmount } from '@/shared/validation';
import { Button, Check, Spinner, TextInput } from '@checkrx/pay-component-library';
import { AxiosError, isAxiosError } from 'axios';
import { useState } from 'react';
import styled from 'styled-components';

const SupportLink = styled.a`
  color: ${colors.accentTeal};
  font-size: 600;
  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: ${colors.accentTeal};
  }
`;

const successToMessage = (transferId: string) => {
  return (
    <CenteredSuccessText>
      Successfully created a new pending transfer with ID: {transferId}
    </CenteredSuccessText>
  );
};

const errorToMessage = (err: Error) => {
  if (isAxiosError(err)) {
    const {
      data: { error_message: errorMessage, details: errorDetails },
    } = err?.response || { data: {} };

    let details = errorDetails || [];
    if (details?.error) {
      details = details?.error?.details || [];
    }

    if (details.includes('external_bank_account_not_setup')) {
      return (
        <>
          <CenteredErrorText key="error">
            Your external bank account is not setup with Checkr Pay. Please contact{' '}
            <SupportLink
              href="https://customersupport.checkrpay.com/hc/en-us"
              target="_blank"
              rel="noreferrer"
            >
              Customer Support
            </SupportLink>{' '}
            for setup help.
          </CenteredErrorText>
        </>
      );
    }

    return (
      <>
        <CenteredErrorText key="error">{`${errorMessage}`}</CenteredErrorText>
        {details.map(({ message, type }: { message: string; type: string }) => (
          <CenteredErrorText key={type}>{message}</CenteredErrorText>
        ))}
      </>
    );
  } else {
    return <CenteredErrorText>{`${err.message}`}</CenteredErrorText>;
  }
};

export default function DepositFundsModal() {
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [depositAmount, setDepositAmount] = useState<number | null>(null);

  const {
    isLoading: createDepositIsLoading,
    isError: createDepositHasError,
    error: createDepositError,
    isSuccess: createDepositSucceeded,
    data: createDepositData,
    mutate: createDeposit,
  } = useCreateDeposit();

  const canSubmit = hasConfirmed && isAmount(depositAmount) && !createDepositIsLoading;

  const onSubmitButtonClick = async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (canSubmit) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      createDeposit({ amount: depositAmount! });
    }
  };

  // Success should show a hooray graphic with a message about the completion
  if (createDepositSucceeded) {
    return (
      <ImgWrapper>
        <SuccessGraphic />
        <ResultsRow>{successToMessage(createDepositData.transfer.transferId)}</ResultsRow>{' '}
      </ImgWrapper>
    );
  }

  if (createDepositHasError) {
    return (
      <ImgWrapper>
        <ErrorGraphic />
        <ResultsRow>{errorToMessage(createDepositError as AxiosError)}</ResultsRow>
      </ImgWrapper>
    );
  }

  return (
    // Otherwise we're showing the data and asking for confirmation
    <ContentWrapper>
      <DescriptionText>
        Use this to fund your Checkr Pay account for issuing payouts. Checkr will initiate an ACH
        transfer from your bank account registered with us to your Checkr Pay account. Please double
        check the deposit amount. The transfer is final once you submit. Funds usually become
        available in 2-3 business days.
      </DescriptionText>
      <LabelRow>
        <LabelText>Deposit Amount</LabelText>
      </LabelRow>
      <TextInput
        width="280px"
        placeholder="Deposit Amount"
        height="60px"
        onChange={(e) => {
          // Parse from Intl number format to integer value
          if (e.target.value.includes('$')) {
            setDepositAmount(
              parseInt(e.target.value.slice(1).replaceAll(',', '').split('.').join(''))
            );
          } else {
            setDepositAmount(parseInt(e.target.value.replaceAll(',', '')));
          }
        }}
        value={
          depositAmount
            ? Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format((depositAmount || 0) / 100)
            : undefined
        }
      />
      <CheckRow>
        <Check
          checked={hasConfirmed}
          onClick={() => {
            setHasConfirmed(!hasConfirmed);
          }}
        />
        <Legalese>
          I&apos;ve confirmed the information above and authorize Checkr Pay to perform the
          transfer.
        </Legalese>
      </CheckRow>
      <ButtonRow>
        <Button
          text="Submit"
          sizeVariant="big"
          colorVariant="brand"
          disabled={!canSubmit}
          width="155px"
          onClick={onSubmitButtonClick}
        />
        {createDepositIsLoading ? <Spinner size="30px" /> : <></>}
      </ButtonRow>
    </ContentWrapper>
  );
}
