import dayjs from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as PayoutsIcon } from '@/shared/assets/payouts-tab-icon.svg';
import { CardTitle, ContentCard } from '@/shared/components/Card';
import { Button, Dropdown, Modal, Table, TextInput } from '@checkrx/pay-component-library';

import { useWorkers } from '@/services/useWorkers';

import { toast } from '@/app/wrappers/Toaster/Toaster';
import { useCustomer } from '@/services/useCustomerProfile';
import {
  ControlsRow,
  DEFAULT_RESULTS_PER_TABLE_PAGE,
  TableStatusText,
} from '@/shared/components/TableComponents';
import { TablePagination } from '@/shared/components/TablePagination';
import { colors } from '@/shared/styles';
import { Worker, WorkerStatus } from '@/shared/types';
import { generateAndDownloadCsv } from '@/shared/util';
import { Link } from 'react-router-dom';
import ConfirmDirectDepositModal from './ConfirmDirectDepositModal';

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  padding-bottom: 3px;

  &:hover {
    background: ${colors.primaryWhite};
    filter: brightness(95%);
    cursor: pointer;
    border-radius: 4px;
  }
`;

const WorkersCard = styled(ContentCard)`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  min-height: fit-content;
  gap: 30px;
`;
const BetweenWrapper = styled.div`
  display: flex;
  width: 100%;
  min-width: fit-content;
  height: fit-content;
  align-items: start;
  justify-content: space-between;
`;

const WorkerIdWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  height: 100%;
`;

const WORKERS_PER_PAGE = DEFAULT_RESULTS_PER_TABLE_PAGE;

const workerStatusToDisplay = (status: WorkerStatus | null) => {
  if (!status) {
    return <></>;
  }

  // Branch on the status type
  switch (status) {
    case WorkerStatus.Onboarded:
      return <TableStatusText color={colors.accentOrange}>Onboarded</TableStatusText>;
    case WorkerStatus.Pending:
      return <TableStatusText color={colors.accentOrange}>Pending</TableStatusText>;
    case WorkerStatus.RequirePII:
      return <TableStatusText color={colors.accentOrange}>Require PII</TableStatusText>;
    case WorkerStatus.ManualReview:
      return <TableStatusText color={colors.accentOrange}>Manual Review</TableStatusText>;
    case WorkerStatus.Active:
      return <TableStatusText color={colors.accentGreen}>Active</TableStatusText>;
    case WorkerStatus.Denied:
      return <TableStatusText color={colors.accentRed}>Denied</TableStatusText>;
    case WorkerStatus.PendingClosure:
      return <TableStatusText color={colors.accentOrange}>Pending Closure</TableStatusText>;
    case WorkerStatus.Closed:
      return <TableStatusText color={colors.accentRed}>Closed</TableStatusText>;
    default:
      return <TableStatusText color={colors.accentRed}>N/A</TableStatusText>;
  }
};

export default function WorkersTable({ payWorkerId }: { payWorkerId?: string }) {
  const [searchString, setSearchString] = useState('');
  const [pageNumber, setPageNumber] = useState(0);

  // Worker filtering state
  const [selectedStatus, setSelectedStatus] = useState<WorkerStatus | ''>('');
  const [workerId, _setWorkerId] = useState(payWorkerId || '');
  const [workerEmail, _setWorkerEmail] = useState('');
  const [workerLegalName, _setWorkerLegalName] = useState('');

  const {
    data: fetchedWorkers,
    isError,
    isLoading,
  } = useWorkers(
    selectedStatus !== '' ? selectedStatus : undefined,
    workerId,
    workerEmail,
    workerLegalName,
    searchString
  );

  // enabling direct deposit
  const { data: customer } = useCustomer();
  const canToggleDirectDeposit =
    customer?.payoutMethods.achDirectDeposit.canBeEnabledForSpecificWorkers &&
    !customer.payoutMethods.achDirectDeposit.enabled;
  const [selectedWorker, setSelectedWorker] = useState<Worker>();
  const [showConfirmDirectDeposit, setShowConfirmDirectDeposit] = useState(false);
  const handleOpenConfirmDirectDepositModal = (worker: Worker) => {
    setSelectedWorker(worker);
    setShowConfirmDirectDeposit(true);
  };

  const startIdx = pageNumber * WORKERS_PER_PAGE;
  const endIdx = (pageNumber + 1) * WORKERS_PER_PAGE;
  const workersForTable = (fetchedWorkers || []).slice(startIdx, endIdx).map((worker) =>
    // Map fetched workers to displayable data
    ({
      id: worker.id,
      workerId: (
        <WorkerIdWrapper
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(worker.id);
            toast({
              type: 'info',
              message: `${
                worker.profile?.legalName ?? worker.profile?.email
              }'s id copied to clipboard`,
              duration: 4500,
            });
          }}
        >
          {worker.id}
        </WorkerIdWrapper>
      ),
      payoutHistory: (
        <Link to={`/customer/payouts?payWorkerId=${worker.id}`}>
          <LinkWrapper>
            <PayoutsIcon />
          </LinkWrapper>
        </Link>
      ),
      date: dayjs(worker.createdAt).format('MMM DD YYYY'),
      legalName: worker?.profile?.legalName || '',
      email: worker?.profile?.email || '',
      metadata: worker.metadata || '',
      status: workerStatusToDisplay(worker?.status),
      direct_deposit: worker.payoutMethods?.available?.includes('ACHDirectDeposit') ? (
        'Offered'
      ) : (
        <Button
          text="Offer Direct Deposit"
          onClick={() => handleOpenConfirmDirectDepositModal(worker)}
        />
      ),
    })
  );
  const lastPage = Math.floor((fetchedWorkers || []).length / WORKERS_PER_PAGE);

  return (
    <>
      <WorkersCard>
        <CardTitle>Workers</CardTitle>
        <BetweenWrapper>
          <ControlsRow>
            <TextInput
              value={searchString}
              width="400px"
              placeholder="Filter by search term"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchString(e.target.value || '');
                setPageNumber(0);
              }}
            />
            <Dropdown
              options={[
                { label: 'Any Status', value: '' },
                { label: 'Onboarded', value: WorkerStatus.Onboarded },
                { label: 'Pending', value: WorkerStatus.Pending },
                { label: 'Require PII', value: WorkerStatus.RequirePII },
                { label: 'Manual Review', value: WorkerStatus.ManualReview },
                { label: 'Active', value: WorkerStatus.Active },
                { label: 'Denied', value: WorkerStatus.Denied },
                { label: 'Closed', value: WorkerStatus.Closed },
                { label: 'Pending Closure', value: WorkerStatus.PendingClosure },
              ]}
              closeOnChange
              closeOnOutsideClick
              onSelect={(opt) => {
                setSelectedStatus(opt.value as WorkerStatus);
                setPageNumber(0);
              }}
            />
          </ControlsRow>
          <Button
            text="Generate CSV"
            sizeVariant="big"
            colorVariant="brand"
            width="165px"
            icon="file-text"
            onClick={() =>
              generateAndDownloadCsv(fetchedWorkers || [], (worker) => ({
                id: worker.id,
                email: worker?.profile?.email,
                phoneNumber: worker?.profile?.phoneNumber,
                firstName: worker?.profile?.legalName?.split(' ')[0] || '',
                // We want the last name to include the rest of the names
                lastName: worker?.profile?.legalName?.split(' ').slice(1).join(' ') || '',
                workerStatus: worker.status,
                street: worker?.profile?.address?.street,
                state: worker?.profile?.address?.state,
                street2: worker?.profile?.address?.street2 || '',
                city: worker?.profile?.address?.city || '',
                postalCode: worker?.profile?.address?.postalCode || '',
                selectedPayoutMethod: worker?.payoutMethods?.selected,
                availablePayoutMethods: worker?.payoutMethods?.available || [],
                metadata: worker.metadata,
              }))
            }
          />
        </BetweenWrapper>
        <Table
          data={workersForTable}
          error={isError}
          loading={isLoading}
          empty={(workersForTable || []).length === 0}
          columns={[
            { title: 'Worker ID', field: 'workerId', flex: 1, wrapOverflow: false, isText: false },
            { title: 'Name', field: 'legalName', flex: 1, wrapOverflow: false },
            { title: 'Email', field: 'email', flex: 2, wrapOverflow: false },
            { title: 'Created At', field: 'date', flex: 1 },
            {
              title: 'Metadata',
              field: 'metadata',
              flex: 1.5,
              wrapOverflow: false,
            },
            { title: 'Status', field: 'status', flex: 0.5, isText: false },
            {
              title: 'Payouts',
              field: 'payoutHistory',
              flex: 0.5,
              wrapOverflow: false,
              isText: false,
            },
            ...(canToggleDirectDeposit
              ? [
                  {
                    title: 'Offer Direct Deposit',
                    field: 'direct_deposit',
                    flex: 1.5,
                    wrapOverflow: false,
                    isText: false,
                  },
                ]
              : []),
          ]}
          width="100%"
          height="fit-content"
        />
        {lastPage > 0 && (
          <TablePagination
            curPageNumber={pageNumber}
            lastPageNumber={lastPage}
            setCurPageNumber={setPageNumber}
          />
        )}
      </WorkersCard>
      <Modal
        isOpen={showConfirmDirectDeposit}
        close={() => {
          setShowConfirmDirectDeposit(false);
        }}
        headerText="Confirm offering direct deposit"
        modalContent={
          <ConfirmDirectDepositModal
            worker={selectedWorker as Worker}
            onClose={() => setShowConfirmDirectDeposit(false)}
          />
        }
      />
    </>
  );
}
